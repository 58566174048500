import { Flex } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomText from "../text/CustomText";

function TradingViewChart() {
  const container = useRef<any>();

  const [activeTime, setActiveTime] = useState("D");

  const TIMES = [
    {
      key: "1m",
      value: "1",
    },
    {
      key: "15m",
      value: "15",
    },
    {
      key: "1H",
      value: "60",
    },
    {
      key: "1D",
      value: "D",
    },
    {
      key: "1W",
      value: "W",
    },
  ];

  useEffect(() => {
    container.current.innerHTML = "";
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "autosize": true,
          "symbol": "BINANCE:BTCUSDT",
          "interval": "${activeTime}",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "hide_top_toolbar": true,
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
    container.current.appendChild(script);
  }, [activeTime]);

  return (
    <Flex w="100%" gap="25px" flexDir="column" mt={{ base: "16px", lg: "0px" }}>
      <Flex
        gap="19px"
        py="9px"
        px="19px"
        borderBottom="1px solid"
        borderColor="extsy.dark500"
        align="center"
        justify={{ base: "center", lg: "flex-start" }}
      >
        <CustomText text="Time" size="14px" color="extsy.neutral400" />
        {TIMES.map((time) => (
          <CustomText
            key={time?.key}
            text={time?.key}
            size="14px"
            color={activeTime === time?.value ? "#F0B90B" : "extsy.neutral400"}
            cursor="pointer"
            onClick={() => setActiveTime(time?.value)}
          />
        ))}
      </Flex>
      <div
        className="tradingview-widget-container"
        ref={container}
        style={{ minHeight: "457px", width: "100%" }}
      >
        <div
          className="tradingview-widget-container__widget"
          style={{ height: "calc(100% - 32px)", width: "100%" }}
        ></div>
      </div>
    </Flex>
  );
}

export default TradingViewChart;
