import { Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
// import CardImg from "../../assets/images/dashboard/cardImage.svg"
import PlusIcon from "../../assets/images/dashboard/plusIcon.svg";
// import LogoIcon from "../../assets/images/logo-icon.svg"
// import EyeIcon from "../../assets/images/dashboard/eyeIcon.svg "
import CustomModal from "../modal/customModal";
import QRImg from "../../assets/images/QRImg.svg";
// import CustomInput from "../form/CustomInput";
import VCardInput from "../form/vCardInput";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import VCardSelect from "../form/vCardSelect";
import USDTIcon from "../../assets/images/usdt-ico.svg";
import DashboardSlider from "../dashboard/dashboardSlider";
import CancelAlertIco from "../../assets/images/cancel-alert-ico.svg";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import ExtsyLogo from "../../assets/images/logo-icon.svg";
import MasterCardIco from "../../assets/images/master-card-ico.svg";
import FreezeBg from "../../assets/images/freeze-bg.png";

function VirtualCards() {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [t("Through Card"), t("Crypto Payment")];
  const handleTabClick = (index: any) => {
    setTabIndex(index); // Update the active tab index
  };

  const [modalopen, setModalOpen] = useState(false);
  const [CryptoPayOpen, setCryptoPayOpen] = useState(false);
  const [CardCreatedOpen, setCardCreatedOpen] = useState(false);
  const [CardSuccessOpen, setCardSuccessOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(!modalopen);
  const handleModalClose = () => setModalOpen(false);
  const [CardTypeOpen, setCardTypeOpen] = useState(false);
  const handleCardTypeOpen = () => {
    setCardTypeOpen(!CardTypeOpen);
    handleModalClose();
  };
  const handleCardTypeClose = () => setCardTypeOpen(false);

  const handleCryptopayOpen = () => {
    setCryptoPayOpen(!CryptoPayOpen);
    handleCardTypeClose();
  };
  const handleCryptoPayClose = () => setCryptoPayOpen(false);

  const handleCardCreatedOpen = () => {
    setCardCreatedOpen(!CardCreatedOpen);
    handleCryptoPayClose();
  };

  const handleCardCreatedClose = () => setCardCreatedOpen(false);

  // const handleCardSuccessOpen = () => {
  //   setCardSuccessOpen(!CardSuccessOpen);
  //   handleCryptoPayClose();
  // };

  const handleCardSuccessClose = () => setCardSuccessOpen(false);

  const [isHidden, setIsHidden] = useState(true);

  return (
    <>
      <Flex
        p={{ base: "16px", md: "32px" }}
        gap="32px"
        flexDir="column"
        bg="extsy.neutral1000"
        borderRadius="24px"
        boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      >
        <Flex align="center" justify="space-between">
          <CustomText
            text={t("Virtual Cards ")}
            size={{ base: "16px", md: "24px" }}
            weight={{ base: "600", md: "700" }}
            color="extsy.baseWhite"
            family="Titillium Web"
          />
        </Flex>
        <Flex
          gap={"24px"}
          flexDir={{
            base: "column",
            md: "row",
          }}
          align="center"
        >
          <Flex
            border={"1px solid"}
            borderColor={"extsy.secondary"}
            maxW={"204px"}
            w="100%"
            minH={{ base: "262px", md: "342px" }}
            h="100%"
            justify={"space-between"}
            align={"center"}
            flexDir={"column"}
            bg={"extsy.vCard"}
            borderRadius={"20px"}
            padding={"16px"}
          >
            <Flex justify={"space-between"} minW={"100%"}>
              <Image src={ExtsyLogo} alt="icon" h="27px" w="38px" />
              <Image src={MasterCardIco} alt="icon" w="37px" h="23px" />
            </Flex>
            <Flex flexDir={"column"} gap={"24px"}>
              <Flex flexDir="column" gap="6.3px">
                <CustomText
                  text="Serra Alexandra"
                  size="10.7px"
                  color="extsy.baseWhite"
                />
                <CustomText
                  text={
                    isHidden ? "**** **** **** 3934" : "3674 3927 3782 3934"
                  }
                  size="15px"
                  Weight="700"
                  color="extsy.baseWhite"
                  ls="-0.604px"
                  family="Courier Prime"
                />
              </Flex>
              <Flex justify="space-between" align="center">
                <Flex flexDir="column" gap="6.3px">
                  <CustomText
                    text={t("expiryDate")}
                    color="extsy.baseWhite"
                    size="10.7px"
                  />
                  <CustomText
                    text={isHidden ? "**/**" : "03/28"}
                    color="extsy.baseWhite"
                    size="15px"
                    weight="700"
                    ls="-0.604px"
                    family="Courier Prime"
                    cursor="pointer"
                  />
                </Flex>
                <Flex flexDir="column" gap="6.3px">
                  <CustomText
                    text={t("cvc")}
                    color="extsy.baseWhite"
                    size="10.7px"
                  />
                  <CustomText
                    text={isHidden ? "***" : "947"}
                    color="extsy.baseWhite"
                    size="15px"
                    weight="700"
                    ls="-0.604px"
                    family="Courier Prime"
                    cursor="pointer"
                  />
                </Flex>
                {isHidden ? (
                  <IconEye
                    size="20px"
                    color="#FEF8C3"
                    cursor="pointer"
                    onClick={() => {
                      setIsHidden(false);
                    }}
                  />
                ) : (
                  <IconEyeOff
                    size="20px"
                    color="#FEF8C3"
                    cursor="pointer"
                    onClick={() => {
                      setIsHidden(true);
                    }}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            border={"1px solid"}
            borderColor="extsy.neutral1000"
            maxW={"204px"}
            w="100%"
            minH={{ base: "262px", md: "342px" }}
            justify={"space-between"}
            align={"center"}
            flexDir={"column"}
            bgImage={FreezeBg}
            bgSize="cover"
            bgRepeat="no-repeat"
            borderRadius={"20px"}
            padding={"16px"}
          >
            <Flex justify={"space-between"} minW={"100%"}>
              <Image src={ExtsyLogo} alt="icon" h="27px" w="38px" />
              <Image src={MasterCardIco} alt="icon" w="37px" h="23px" />
            </Flex>
            <Flex flexDir={"column"} gap={"24px"}>
              <Flex flexDir="column" gap="6.3px">
                <CustomText
                  text="Serra Alexandra"
                  size="10.7px"
                  color="extsy.baseWhite"
                />
                <CustomText
                  text={
                    isHidden ? "**** **** **** 3934" : "3674 3927 3782 3934"
                  }
                  size="15px"
                  Weight="700"
                  color="extsy.baseWhite"
                  ls="-0.604px"
                  family="Courier Prime"
                />
              </Flex>
              <Flex justify="space-between" align="center">
                <Flex flexDir="column" gap="6.3px">
                  <CustomText
                    text={t("expiryDate")}
                    color="extsy.baseWhite"
                    size="10.7px"
                  />
                  <CustomText
                    text={isHidden ? "**/**" : "03/28"}
                    color="extsy.baseWhite"
                    size="15px"
                    weight="700"
                    ls="-0.604px"
                    family="Courier Prime"
                    cursor="pointer"
                  />
                </Flex>
                <Flex flexDir="column" gap="6.3px">
                  <CustomText
                    text={t("cvc")}
                    color="extsy.baseWhite"
                    size="10.7px"
                  />
                  <CustomText
                    text={isHidden ? "***" : "947"}
                    color="extsy.baseWhite"
                    size="15px"
                    weight="700"
                    ls="-0.604px"
                    family="Courier Prime"
                    cursor="pointer"
                  />
                </Flex>
                {isHidden ? (
                  <IconEye
                    size="20px"
                    color="#FEF8C3"
                    cursor="pointer"
                    onClick={() => {
                      setIsHidden(false);
                    }}
                  />
                ) : (
                  <IconEyeOff
                    size="20px"
                    color="#FEF8C3"
                    cursor="pointer"
                    onClick={() => {
                      setIsHidden(true);
                    }}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            border={"1px dashed"}
            borderColor={"extsy.neutral600"}
            minW={"113px"}
            w={{ base: "100%", md: "auto" }}
            minH={{ base: "40px", md: "342px" }}
            align="center"
            justify="center"
            bg={"extsy.bgCard"}
            borderRadius={"20px"}
            onClick={handleModalOpen}
          >
            <Flex
              gap="8px"
              justify={"center"}
              align={"center"}
              transform={{ md: "rotate(-90deg)" }}
            >
              <Image src={PlusIcon} alt="PlusIcon" />
              <Text
                fontSize="14px"
                fontFamily="Metropolis"
                color="extsy.baseWhite"
              >
                {t("Add Card ")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <CustomModal
        isOpen={modalopen}
        onClose={handleModalClose}
        onSubmit={handleCardTypeOpen}
        headerText={"Create New Card"}
        btnText={"Continue"}
        closeBtntext={"Cancel"}
        width={"700px"}
        bgtopBlur={"extsy.bgCardTopBlur"}
      >
        <Flex
          flexDir={"column"}
          gap={"12px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <CustomText
            text={t("Enter Card Name ")}
            size="14px"
            color="extsy.neutral300"
            family="Metropolis"
            align="left"
          />
          <VCardInput
            placeholder=""
            type="text"
            value={""}
            bg={"extsy.neutral900"}
          />
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={CardTypeOpen}
        onClose={handleCardTypeClose}
        onSubmit={handleCryptopayOpen}
        headerText={"Create New Card"}
        btnText={"Pay To Create Card"}
        closeBtntext={"Close"}
        width={"700px"}
        bgtopBlur={"extsy.bgCardTopBlur"}
      >
        <Flex
          flexDir={"column"}
          gap={"12px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <Tabs index={tabIndex}>
            <TabList
              justifyContent={"center"}
              gap={"40px"}
              borderBottom={"1px solid"}
              borderColor={"extsy.neutral300"}
            >
              {tabs?.map((tab, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick(index)}
                  borderBottom={
                    tabIndex === index ? "2px solid #FEF9CC " : "none"
                  }
                  // borderColor={tabIndex === index ? '' :'none'}
                  cursor="pointer"
                  //  bg={tabIndex === index ? "extsy.g1" : "transparent"}
                  color="#FEF9CC"
                >
                  {tabIndex === index ? (
                    <CustomText
                      text={tab}
                      family="Titillium Web"
                      weight="700"
                      size={{ base: "14px", lg: "15.4px" }}
                      lh="22px"
                      bg="extsy.g1"
                      cursor="pointer"
                    />
                  ) : (
                    <CustomText
                      text={tab}
                      family="Titillium Web"
                      size="15.4px"
                      lh="22px"
                      cursor="pointer"
                      color="extsy.white"
                    />
                  )}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              <TabPanel padding={"none"}>
                <Flex flexDir={"column"} gap={{ base: "16px", lg: "32px" }}>
                  <Flex
                    flexDir={"column"}
                    pt={{ base: "16px", lg: "32px" }}
                    gap={"12px"}
                  >
                    <CustomText
                      text={t("Card Number ")}
                      size="14px"
                      color="extsy.neutral300"
                      family="Metropolis"
                    />
                    <VCardInput
                      placeholder="Card No"
                      type="text"
                      value={""}
                      bg={"extsy.neutral900"}
                    />
                  </Flex>
                  <Flex
                    justify={"space-between"}
                    flexDir={{ base: "column", lg: "row" }}
                    gap="16px"
                  >
                    <Flex flexDir={"column"} gap={"12px"}>
                      <CustomText
                        text={t("CVC ")}
                        size="14px"
                        color="extsy.neutral300"
                        family="Metropolis"
                      />
                      <VCardInput
                        placeholder="--/--"
                        type="text"
                        value={""}
                        bg={"extsy.neutral900"}
                      />
                    </Flex>
                    <Flex flexDir={"column"} gap={"12px"}>
                      <CustomText
                        text={t("Expiry ")}
                        size="14px"
                        color="extsy.neutral300"
                        family="Metropolis"
                      />
                      <VCardInput
                        placeholder="MM - YYYY"
                        type="text"
                        value={""}
                        bg={"extsy.neutral900"}
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    justify={"space-between"}
                    borderTop={"1px solid "}
                    borderColor={"extsy.neutral900"}
                    pt={"24px"}
                  >
                    <CustomText
                      text={t("Total Amount ")}
                      color="extsy.neutral300"
                      family="Metropolis"
                    />
                    <CustomText
                      text={t("$10.00")}
                      size={{ base: "20px", lg: "32px" }}
                      color="extsy.extsy.baseWhite"
                      family="Metropolis"
                    />
                  </Flex>
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex flexDir={"column"} py="20px" gap={"12px"}>
                  <CustomText
                    text={t("Coin ")}
                    size="14px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                  <VCardSelect />
                </Flex>
                <Flex
                  justify={"space-between"}
                  borderTop={"1px solid "}
                  borderColor={"extsy.neutral900"}
                  py={"20px"}
                >
                  <CustomText
                    text={t("Total Amount ")}
                    size="14px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                  <CustomText
                    text={t("10.00 USDT")}
                    size="14px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </CustomModal>

      <CustomModal
        isOpen={CryptoPayOpen}
        onClose={handleCryptoPayClose}
        onSubmit={() => handleCardCreatedOpen()}
        headerText={"Pay in Crypto"}
        noFooter={true}
        width={"780px"}
        bgtopBlur={"extsy.bgCardTopBlur"}
      >
        <Flex
          flexDir={"column"}
          gap={"12px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <Flex
            justify={"space-between"}
            align={{ lg: "center" }}
            gap={{ base: "16", lg: "56px" }}
            pb={"24px"}
            flexDir={{
              base: "column",
              lg: "row",
            }}
          >
            <Flex flexDir={"column"} gap={{ base: "16px", lg: "40px" }}>
              <Flex gap={"12px"} align={"center"}>
                <Image
                  src={USDTIcon}
                  alt="icon"
                  width={"40px"}
                  height={"40px"}
                />
                <Flex flexDir={"column"} gap={"5px"}>
                  <CustomText
                    text={t("Amount ")}
                    size="18px"
                    color="extsy.neutral300"
                    family="Titillium Web"
                  />
                  <CustomText
                    text={t("20.00 USDT ")}
                    size="24px"
                    color="extsy.baseWhite"
                    weight="700"
                    family="Titillium Web"
                  />
                </Flex>
              </Flex>
              <Flex
                flexDir={{ base: "row", lg: "column" }}
                justify={"space-between"}
                gap={{ base: "16px", lg: "40px" }}
              >
                <Flex flexDir={"column"} gap={"5px"}>
                  <CustomText
                    text={t("To this wallet")}
                    size="18px"
                    color="extsy.neutral300"
                    family="Titillium Web"
                  />
                  <CustomText
                    text={t("0x000...0000")}
                    size="24px"
                    color="extsy.baseWhite"
                    weight="700"
                    family="Titillium Web"
                  />
                </Flex>
                <Flex
                  flexDir={"column"}
                  gap={"5px"}
                  align={{ base: "flex-end", lg: "flex-start" }}
                >
                  <CustomText
                    text={t("Time left for the transaction")}
                    size="18px"
                    color="extsy.neutral300"
                    family="Titillium Web"
                  />
                  <CustomText
                    text={t("20:53:26 ")}
                    size="24px"
                    color="extsy.baseWhite"
                    weight="700"
                    family="Titillium Web"
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex justify="center">
              <Image
                src={QRImg}
                alt="qrCode"
                height={{ base: "200px", lg: "260px" }}
                width={{ base: "200px", lg: "260px" }}
              />
            </Flex>
          </Flex>
          <Flex pt={"16px"}>
            <DashboardSlider />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={CardCreatedOpen}
        onClose={handleCardCreatedClose}
        onSubmit={() => {
          handleCardCreatedClose();
        }}
        headerText={t("Virtual Credit Card Created")}
        btnText={t("Done")}
        closeBtntext={t("close")}
        btnContinueWidth={"336px"}
        btnCloseWidth={"200px"}
        width={"600px"}
      >
        <Flex p="32px 24px" flexDir="column" gap="32px" alignItems="center">
          <Image src={CancelAlertIco} alt="cancel" />
          <CustomText
            text={t("Congratulation!")}
            color="extsy.baseWhite"
            size="24px"
            family="Tiltillium Web"
          />
          <CustomText
            text={t("You have successfully created your Extsy Virtual Card")}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={CardSuccessOpen}
        onClose={handleCardSuccessClose}
        onSubmit={() => {
          handleCardSuccessClose();
        }}
        headerText={t("Virtual Credit Card")}
        btnText={t("Upgrade Plan")}
        closeBtntext={t("Not Now")}
        btnContinueWidth={"336px"}
        btnCloseWidth={"200px"}
        width={"600px"}
      >
        <Flex p="32px 24px" flexDir="column" gap="32px" alignItems="center">
          <Image src={CancelAlertIco} alt="cancel" />
          <CustomText
            text={t("Not Successful!")}
            color="extsy.baseWhite"
            size="24px"
            family="Tiltillium Web"
          />
          <CustomText
            text={t(
              "Sorry! you cannot create more than two virtual cards as per your subscription plan. Upgrade your plan and enjoy more virtual cards."
            )}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
      </CustomModal>
    </>
  );
}

export default VirtualCards;
