import {
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useTranslation } from "react-i18next";
import GradientText from "../../components/text/GradientText";

function AMLHistory() {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap={{ base: "16px", lg: "24px" }}>
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900" maxW="383px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Date"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "160px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Currency"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "160px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Address"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Result"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {new Array(5)?.fill(1)?.map((_, index) => (
              <Tr key={index}>
                <Td
                  height="54px"
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  py="0"
                >
                  <CustomText text="17-08-2024 08:26 PM" size="14px" />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText text="BTC" size="14px" />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText text="0x0000...00000" size="14px" />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex
                    align="center"
                    justify="center"
                    bg="#15B0971A"
                    w="44px"
                    h="20px"
                    borderRadius="30px"
                  >
                    <CustomText
                      color="#15B097"
                      size="10px"
                      weight="500"
                      text="Clean"
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AMLHistory;
