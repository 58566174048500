import { Box, Input } from "@chakra-ui/react";
import React from "react";

function VCardInput({
  type,
  placeholder,
  value,
  onChange,
  rightIco,
  bg,
  radius,
  maxW,
  color,
}: any) {
  return (
    <Box pos="relative">
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        borderBottom="none"
        borderTop="none"
        borderLeft="none"
        borderRight="none"
        borderRadius={radius || "16px"}
        color={color || "extsy.baseWhite"}
        fontSize="16px"
        fontFamily="Titillium Web"
        pl="16px"
        maxW={maxW || "700px"}
        bg={bg}
        height={"56px"}
        pr={rightIco ? "30px" : "auto"}
        padding={"9.5px 19px"}
        _placeholder={{
          color: "extsy.text",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Metropolis",
        }}
        _focusVisible={{
          border: "none",
        }}
      />
      {rightIco && (
        <Box pos="absolute" right="0" top="0" mt="24px" mr="16px">
          {rightIco}
        </Box>
      )}
    </Box>
  );
}

export default VCardInput;
