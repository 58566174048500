import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import TableSearchInput from "../form/TableSearchInput";
import { IconCheck, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import GradientText from "../text/GradientText";
import { RECENT_TRANSACTIONS } from "../../utils/constants";

function RecentTransactions({ minH, mt }: any) {
  const { t } = useTranslation();

  const filterRef = useRef(null);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("thisWeek");

  const FILTER_ITEMS = [
    {
      id: "thisWeek",
      name: t("thisWeek"),
    },
    {
      id: "last14Days",
      name: t("last14Days"),
    },
    {
      id: "lastMonth",
      name: t("lastMonth"),
    },
    {
      id: "last2Month",
      name: t("last2Month"),
    },
  ];

  const getBgColor = (status: string) => {
    switch (status) {
      case "Completed":
        return "#56CE77";
      case "In Process":
        return "#FFA333";
      case "Cancel":
        return "#FF6574";
      default:
        return "extsy.neutral300";
    }
  };

  useOutsideClick({
    ref: filterRef,
    handler: () => {
      setIsFilterOpen(false);
    },
  });

  return (
    <Flex
      p={{ base: "16px", lg: "24px" }}
      gap="16px"
      flexDir="column"
      bg="extsy.neutral1000"
      borderRadius={{ base: "16px", lg: "24px" }}
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      marginTop={mt || ""}
    >
      <Flex
        align={{ md: "center" }}
        justify="space-between"
        pb="20px"
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "10px", lg: "20px" }}
      >
        <CustomText
          text={t("recentTransaction")}
          size={{ base: "16px", lg: "24px" }}
          weight={{ base: "400", lg: "700" }}
          color="extsy.baseWhite"
          family="Titillium Web"
        />
        <Flex align="center" gap="10px">
          <TableSearchInput
            placeholder={t("searchTransactions")}
            maxW={{ base: "204px", lg: "409px" }}
            minW="140px"
          />
          <Box pos="relative">
            <Flex
              height="38px"
              gap="12px"
              minW={{ lg: "149px" }}
              align="center"
              justify="space-between"
              cursor="pointer"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              ps="24px"
              pe="10px"
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            >
              <CustomText
                cursor="pointer"
                text={t(activeFilter)}
                size="12px"
                color="extsy.baseWhite"
                wrap="nowrap"
              />
              {isFilterOpen ? (
                <IconChevronUp color="#FAFAFA" size="16px" />
              ) : (
                <IconChevronDown color="#FAFAFA" size="16px" />
              )}
            </Flex>
            {isFilterOpen && (
              <Flex
                flexDir="column"
                gap="12px"
                p="16px"
                borderRadius="16px"
                border="1px solid"
                borderColor="extsy.neutral800"
                bg="extsy.neutral900"
                minW="150px"
                pos="absolute"
                top="0"
                right={{ base: "0", lg: "auto" }}
                marginTop="50px"
                mr={{ base: "10px", lg: "auto" }}
                ref={filterRef}
              >
                {FILTER_ITEMS.map((item, index) => (
                  <Flex
                    align="center"
                    justify="space-between"
                    py="8px"
                    cursor="pointer"
                    key={index}
                    onClick={() => {
                      setIsFilterOpen(false);
                      setActiveFilter(item.id);
                    }}
                  >
                    <CustomText
                      text={item?.name}
                      size="14px"
                      color={
                        item?.id === activeFilter
                          ? "extsy.baseWhite"
                          : "extsy.neutral300"
                      }
                      cursor="pointer"
                    />
                    {item?.id === activeFilter && (
                      <IconCheck size="16px" color="#FAFAFA" />
                    )}
                  </Flex>
                ))}
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
      {activeFilter === "thisWeek" ? (
        <Flex
          minH={minH || ""}
          flexDir="column"
          justify="center"
          align="center"
          gap="8px"
        >
          <Image alt="empty" src={EmptyTrxIco} />
          <CustomText
            text={t("noHistoryFound")}
            size="14px"
            color="extsy.neutral400"
          />
        </Flex>
      ) : (
        <TableContainer minH={minH || ""}>
          <Table border="1px solid" color="extsy.neutral900">
            <Thead bg="extsy.neutral900" height="40px">
              <Tr>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("id")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <IconChevronUp
                        cursor="pointer"
                        color="#FAFAFA"
                        size="14px"
                      />
                      <IconChevronDown
                        color="#FAFAFA"
                        size="14px"
                        cursor="pointer"
                        style={{
                          marginTop: -7,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="160px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("amount")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <IconChevronUp
                        cursor="pointer"
                        color="#FAFAFA"
                        size="14px"
                      />
                      <IconChevronDown
                        color="#FAFAFA"
                        size="14px"
                        cursor="pointer"
                        style={{
                          marginTop: -7,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("type")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <IconChevronUp
                        cursor="pointer"
                        color="#FAFAFA"
                        size="14px"
                      />
                      <IconChevronDown
                        color="#FAFAFA"
                        size="14px"
                        cursor="pointer"
                        style={{
                          marginTop: -7,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("date")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <IconChevronUp
                        cursor="pointer"
                        color="#FAFAFA"
                        size="14px"
                      />
                      <IconChevronDown
                        color="#FAFAFA"
                        size="14px"
                        cursor="pointer"
                        style={{
                          marginTop: -7,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
                <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={t("status")}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <IconChevronUp
                        cursor="pointer"
                        color="#FAFAFA"
                        size="14px"
                      />
                      <IconChevronDown
                        color="#FAFAFA"
                        size="14px"
                        cursor="pointer"
                        style={{
                          marginTop: -7,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {RECENT_TRANSACTIONS.map((trx, index) => (
                <Tr key={index}>
                  <Td
                    height="54px"
                    borderColor="extsy.neutral900"
                    borderRight="1px solid"
                  >
                    <CustomText
                      text={trx.id}
                      size="14px"
                      color="extsy.baseWhite"
                    />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={trx.amount} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={trx.type} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText text={trx.date} size="14px" />
                  </Td>
                  <Td borderColor="extsy.neutral900">
                    <Flex
                      w="90px"
                      h="24px"
                      align="center"
                      justify="center"
                      borderRadius="30px"
                      bg={`${getBgColor(trx?.status)}1A`}
                    >
                      <CustomText
                        text={trx.status}
                        size="10px"
                        color={getBgColor(trx?.status)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Flex gap="16px" px="20px" py="14px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default RecentTransactions;
