import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import PrimeIco from "../../assets/images/prime-ico.svg";
import SupremeIco from "../../assets/images/superme-ico.svg";
import QuantlaneIco from "../../assets/images/quantlane-ico.svg";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import FeatureCheck from "../../assets/images/feature-check.svg";
import PrimaryButton from "../button/PrimaryButton";
import CustomModal from "../modal/customModal";
import SupremeIcoDark from "../../assets/images/supreme-ico-dark.svg";
import VCardInput from "../form/vCardInput";

function PlanCard({ plan }: any) {
  const { t } = useTranslation();

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();

  const {
    isOpen: isUpgradeOpen,
    onOpen: onUpgradeOpen,
    onClose: onUpgradeClose,
  } = useDisclosure();

  const getIco = () => {
    switch (plan?.id) {
      case "prime":
        return PrimeIco;
      case "supreme":
        return SupremeIco;
      case "quantlane":
        return QuantlaneIco;
      default:
        return PrimeIco;
    }
  };

  return (
    <Flex
      pos="relative"
      px={{ base: "16px", lg: "32px" }}
      py={{ base: "16px", lg: "40px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      borderTop="4px solid"
      borderColor={plan?.isActive ? "extsy.primary500" : "transparent"}
      bg="extsy.neutral1000"
      //   minW="490.66px"
      //   maxW="490.66px"
      width="100%"
      flexDir="column"
      justify="space-between"
      gap={{ base: "16px", lg: "55px" }}
      overflow={plan?.isPopular ? "hidden" : ""}
    >
      {plan?.isActive && (
        <Flex
          justify="center"
          align="center"
          pos="absolute"
          top="0"
          mt="-16px"
          zIndex="1000"
          bg="extsy.primary500"
          height="28px"
          width="166px"
          borderRadius="999px"
        >
          <CustomText
            text={t("currentPlan")}
            color="extsy.baseWhite"
            size="14px"
            weight="500"
          />
        </Flex>
      )}
      {plan?.isPopular && (
        <>
          <Box
            height="486px"
            width="486px"
            pos="absolute"
            borderRadius="486px"
            bg="#15B097"
            filter="blur(97px)"
            zIndex="1"
            top="0"
            left="0"
            mt="-370px"
            ml="-210px"
          />
          <Box
            height="486px"
            width="486px"
            pos="absolute"
            borderRadius="486px"
            bg="extsy.primary500"
            filter="blur(97px)"
            zIndex="1"
            bottom="0"
            right="0"
            mb="-360px"
            mr="-157px"
          />
        </>
      )}
      <Box zIndex="1000">
        <Flex align="center" justify="space-between">
          <Flex
            cursor="pointer"
            bg="rgba(250, 250, 250, 0.10)"
            align="center"
            gap="12px"
            pe="24px"
            ps="10px"
            h="40px"
            borderRadius="999px"
          >
            <Image src={getIco()} alt="prime" height="22.5px" width="26.4px" />
            <CustomText
              text={plan?.name}
              color="extsy.baseWhite"
              size="14px"
              cursor="pointer"
            />
          </Flex>
          {plan?.isPopular && (
            <Flex
              bg="extsy.secondary100"
              width="85px"
              h="28px"
              borderRadius="999px"
              align="center"
              justify="center"
            >
              <CustomText
                text={t("popular")}
                size="14px"
                color="extsy.dark1000"
                weight="500"
              />
            </Flex>
          )}
        </Flex>
        <Flex mt="24px" align="center" justify="space-between">
          <CustomText
            text={
              <>
                <span>{plan?.price}</span>
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#828282",
                  }}
                >
                  .00
                </span>
              </>
            }
            color="extsy.baseWhite"
            size="41px"
            weight="600"
          />
          <CustomText
            text={t("perMonth")}
            color="extsy.baseWhite"
            size="12px"
          />
        </Flex>
        <Divider my="16px" borderColor="extsy.neutral900" height="1px" />
        <CustomText text={t("features")} color="extsy.baseWhite" weight="600" />
        <Flex mt="24px" gap="24px" flexDir="column">
          {plan?.features?.map((feature: any, idx: number) => (
            <Flex align="center" gap="16px" key={idx}>
              <Image
                src={FeatureCheck}
                alt="check"
                draggable={false}
                height="24px"
                width="24px"
              />
              <CustomText text={feature} color="extsy.baseWhite" />
            </Flex>
          ))}
        </Flex>
      </Box>
      {plan?.isActive ? (
        <Button
          maxW={{ lg: "250px" }}
          w="100%"
          mx="auto"
          h={{ base: "48px", lg: "56px" }}
          bg="extsy.neutral800"
          borderRadius="999px"
          fontFamily="Titillium Web"
          fontSize="16px"
          fontWeight="600"
          color="extsy.neutral300"
          letterSpacing="0.32px"
          zIndex="100"
          _hover={{
            bg: "extsy.neutral800",
          }}
          onClick={onCancelOpen}
        >
          {t("cancelPlan")}
        </Button>
      ) : (
        <Flex justify="center" zIndex="1000">
          <PrimaryButton
            title={t("upgrade")}
            maxW={{ lg: "250px" }}
            height={{ base: "48px", lg: "56px" }}
            onClick={onUpgradeOpen}
          />
        </Flex>
      )}
      <CustomModal
        isOpen={isCancelOpen}
        onClose={() => {
          onCancelClose();
        }}
        onSubmit={() => {
          onCancelClose();
        }}
        headerText={"Cancel Plan"}
        btnText={"Yes, Cancel it"}
        closeBtntext={"Not Now"}
        width={"600px"}
      >
        <Flex
          p={{ base: "24px 16px", lg: "32px 24px" }}
          flexDir="column"
          gap="32px"
          alignItems="center"
        >
          <CustomText
            text={"Are you sure you want to cancel your current plan?"}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isUpgradeOpen}
        onClose={() => {
          onUpgradeClose();
        }}
        onSubmit={() => {
          onUpgradeClose();
        }}
        headerText={"Purchase Plan"}
        btnText={"Purchase Plan"}
        closeBtntext={"Close"}
        width={"600px"}
        bgtopBlur={"extsy.bgCardTopBlur"}
      >
        <Flex
          p={{ base: "24px 16px", lg: "32px 24px" }}
          flexDir="column"
          gap="32px"
        >
          <Flex
            gap="19.2px"
            align="center"
            height={{ base: "56px", lg: "80px" }}
            width="100%"
            justify="center"
            bg="extsy.g1"
            borderRadius="16px"
          >
            <Image
              src={SupremeIcoDark}
              alt="prime"
              height={{ base: "24px", lg: "36px" }}
              width={{ base: "24px", lg: "42px" }}
            />
            <CustomText
              text={plan?.name}
              color="extsy.bg"
              size={{ base: "24px", lg: "32px" }}
              weight="500"
            />
          </Flex>
          <Flex flexDir="column" gap={{ base: "16px", lg: "32px" }}>
            <Flex flexDir="column" gap="12px">
              <CustomText text="Card Number" size="14px" />
              <VCardInput bg="extsy.neutral900" placeholder="Card No" />
            </Flex>
            <Flex
              gap={{ base: "16px", lg: "32px" }}
              align={{ lg: "center" }}
              flexDir={{
                base: "column",
                lg: "row",
              }}
              w="100%"
            >
              <Flex flexDir="column" gap="12px" width="100%">
                <CustomText text="CVC" size="14px" />
                <VCardInput bg="extsy.neutral900" placeholder="--/--" />
              </Flex>
              <Flex flexDir="column" gap="12px" width="100%">
                <CustomText text="Expiry" size="14px" />
                <VCardInput bg="extsy.neutral900" placeholder="MM - YYYY" />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            align="center"
            justify="space-between"
            pt="24px"
            borderTop="1px solid"
            borderColor="extsy.neutral900"
          >
            <CustomText text="Total Amount" color="extsy.baseWhite" />
            <CustomText
              text={`${plan?.price}.00`}
              size="32px"
              weight="600"
              color="extsy.baseWhite"
            />
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default PlanCard;
