import { Box, Flex } from "@chakra-ui/react";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import CustomSelect from "../../../components/form/CustomSelect";
import CustomArea from "../../../components/form/CustomTextArea";

function Step3() {
  return (
    <Flex flexDir="column" gap={{ base: "16px", md: "40px" }}>
      <Flex
        p={{ base: "16px", md: "24px" }}
        gap="10px"
        borderRadius="16px"
        bg="extsy.neutral900"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box>
          <IconInfoCircleFilled size={20} color="#E35CAD" />
        </Box>
        <Flex flexDir="column" gap="8px">
          <CustomText
            text="Please ensure that you comply with p2p rules to avoid account suspension or expulsion from the Extsy Merchant Program.espacially:"
            color="extsy.baseWhite"
          />
          <CustomText
            transform="lowercase"
            color="extsy.baseWhite"
            text="1. IF YOU REQUIRE TAKER’S DOCUMENT FOR VERIFICATION, IT’S NECESSARY TO INDICATE THE REQUIREMENT IN THE REMARK’S SECTION OF YOUR ADVERTISEMENT"
          />
          <CustomText
            transform="lowercase"
            color="extsy.baseWhite"
            text="2. IMPOSING EXTRA FEE ON TAKERS IS NOT ALLOWED IN ALL SCENARIOS"
          />
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", md: "32px" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Terms Tags (Optional)" size="14px" />
          <CustomSelect placeholder="Select Tags" />
          <CustomText text="Select up to 3 tags." size="14px" />
        </Flex>
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Select Region’s Availability" size="14px" />
          <CustomSelect placeholder="All Regions" />
          <CustomText text="" size="14px" />
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", md: "32px" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Terms Tags (Optional)" size="14px" />
          <CustomArea placeholder="15 mins" />
        </Flex>
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Auto Reply (Optional)" size="14px" />
          <CustomArea placeholder="15 mins" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Step3;
