import { Box, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import PlanCard from "../../components/cards/PlanCard";
import ExtsyStats from "../../components/cards/ExtsyStats";
import RequestPayment from "../../components/cards/RequestPayment";
import CustomText from "../../components/text/CustomText";
import TableSearchInput from "../../components/form/TableSearchInput";
import EmptyTableIco from "../../assets/images/empty-trx-ico.svg";
import FAQs from "../../components/cards/FAQs";

function ExtsyPro() {
  const { t } = useTranslation();

  const PLANS = [
    {
      id: "prime",
      price: "$0",
      isActive: true,
      isPopular: false,
      name: t("prime"),
      features: [
        `${t("cashbackOnEverySwap")}`,
        `$100 ${t("monthlyCashbackLimit")}`,
        `5 ${t("amlChecks")}`,
        t("unlimitedP2PTrading"),
        t("cryptoSpotTrading"),
        t("noVirtualCard"),
      ],
    },
    {
      id: "supreme",
      price: "$15",
      isPopular: true,
      isActive: false,
      name: t("supreme"),
      features: [
        `${t("cashbackOnEverySwap")}`,
        `$1,000 ${t("monthlyCashbackLimit")}`,
        `100 ${t("amlChecks")}`,
        t("unlimitedP2PTrading"),
        t("cryptoSpotTrading"),
        t("cryptoFutureTrading"),
        `2 ${t("vitualCards")}`,
      ],
    },
    {
      id: "quantlane",
      price: "$100",
      isPopular: false,
      isActive: false,
      name: t("quantlane"),
      features: [
        `${t("cashbackOnEverySwap")}`,
        `$3,000 ${t("monthlyCashbackLimit")}`,
        `${t("unlimited")} ${t("amlChecks")}`,
        t("unlimitedP2PTrading"),
        t("cryptoSpotTrading"),
        t("cryptoFutureTrading"),
        `5 ${t("vitualCards")}`,
      ],
    },
  ];

  return (
    <Flex flexDir="column" gap={{ base: "16px", lg: "24px" }} mt="24px">
      <Flex
        gap={{ base: "16px", lg: "24px" }}
        wrap={{ base: "wrap", lg: "nowrap" }}
      >
        {PLANS?.map((plan, idx) => (
          <PlanCard key={idx} plan={plan} />
        ))}
      </Flex>
      <ExtsyStats />
      <Grid
        gap={{ base: "16px", lg: "24px" }}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
      >
        <GridItem colSpan={{ base: 12, lg: 5 }}>
          <RequestPayment />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 7 }}>
          <Box
            p={{ base: "16px", lg: "24px" }}
            borderRadius={{ base: "16px", lg: "24px" }}
            bg="extsy.neutral1000"
            boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
            height="100%"
          >
            <Flex align="center" justify="space-between">
              <CustomText
                text="Payout History"
                color="extsy.baseWhite"
                size={{ base: "18px", lg: "24px" }}
                weight={{ base: "600", lg: "700" }}
                family="Titillium Web"
                lh="32px"
              />
              <TableSearchInput
                placeholder="Search Your Assets"
                minW={{ base: "168px", lg: "250px" }}
                maxW={{ base: "168px", lg: "250px" }}
              />
            </Flex>
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              gap="8px"
              h="100%"
              minH="326px"
            >
              <Image
                src={EmptyTableIco}
                alt="empty"
                height="32px"
                width="30.43px"
              />
              <CustomText
                text="No History Available"
                size="14px"
                color="extsy.neutral400"
              />
            </Flex>
          </Box>
        </GridItem>
      </Grid>
      <FAQs />
    </Flex>
  );
}

export default ExtsyPro;
