import React, { useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import AvailableBalanceCard from "../../components/cards/AvailableBalanceCard";
import OrderNowCard from "../../components/cards/OrderNowCard";
import RecentTransactions from "../../components/dashboard/RecentTransactions";
import SwapWidget from "../../components/dashboard/SwapWidget";

function Dashboard() {
  const [isVerified, setIsVerified] = useState(false);
  return (
    <Grid
      gap="24px"
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(12, 1fr)"
    >
      <GridItem colSpan={{ base: 12, "1xl": 8 }}>
        <AvailableBalanceCard />
      </GridItem>
      <GridItem
        colSpan={{ base: 12, lg: 6, "1xl": 4 }}
        order={{
          lg: 3,
          "1xl": 2,
        }}
      >
        <OrderNowCard isVerified={isVerified} setIsVerified={setIsVerified} />
      </GridItem>
      <GridItem
        colSpan={{ base: 12, lg: 6, "1xl": 4 }}
        order={{ base: 2, "1xl": 4 }}
      >
        <SwapWidget />
      </GridItem>
      <GridItem colSpan={{ base: 12, "1xl": 8 }} order={{ base: 4, "1xl": 3 }}>
        <RecentTransactions
          minH={{ base: "250px", lg: "401px" }}
          mt={{ base: "0", "1xl": isVerified ? "-144px" : "-108px" }}
        />
      </GridItem>
    </Grid>
  );
}

export default Dashboard;
