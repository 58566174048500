import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";

import LogoIcon from "../assets/images/logo-icon.svg";

function ForgotLayout() {
  return (
    <Flex bg="extsy.bg" minH="100vh" flexDir={"column"} p="24px 80px">
      <Image
        draggable={false}
        src={LogoIcon}
        alt="logo"
        width="55px"
        height="40px"
      />
      <Flex justify={"center"} align={"center"} minH="90vh">
        <Outlet />
      </Flex>
    </Flex>
  );
}

export default ForgotLayout;
