import {
  Avatar,
  Box,
  Circle,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../../components/text/CustomText";
import SellerAccountIco from "../../../assets/images/seller-account-ico.svg";
import {
  IconCheck,
  IconEyeOff,
  IconInfoCircleFilled,
} from "@tabler/icons-react";
import PrimaryButton from "../../../components/button/PrimaryButton";
import PaymentMethods from "./PaymentMethods";
import MyListing from "./MyListing";
import { useNavigate } from "react-router-dom";

function Seller() {
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);

  const TABS = ["Payment Methods", "My Listing"];

  return (
    <Flex flexDir="column" gap={{ base: "24px", lg: "40px" }}>
      <Flex
        align="center"
        justify="space-between"
        pb="16px"
        borderBottom="1px solid"
        borderColor="extsy.neutral800"
        wrap="wrap"
        gap="16px"
      >
        <Flex align="center" gap="12px">
          <Avatar boxSize="56px" />
          <Flex flexDir="column" gap="12px">
            <Flex align="center" gap="10px">
              <CustomText
                text="Serra Alexandra"
                size="24px"
                weight="700"
                family="Titillium Web"
                color="extsy.baseWhite"
              />
              <Image
                src={SellerAccountIco}
                alt="seller"
                boxSize="18px"
                mt="5px"
              />
            </Flex>
            <Flex align="center" gap="8px">
              <Flex align="center" gap="4px">
                <CustomText text="Email" size="12px" family="Titillium Web" />
                <Circle size="10px" bg="#10B981">
                  <IconCheck size={6} color="#fafafa" />
                </Circle>
              </Flex>
              <CustomText
                text="|"
                size="12px"
                family="Titillium Web"
                color="extsy.neutral800"
              />
              <Flex align="center" gap="4px">
                <CustomText text="KYC" size="12px" family="Titillium Web" />
                <Circle size="10px" bg="#10B981">
                  <IconCheck size={6} color="#fafafa" />
                </Circle>
              </Flex>
              <CustomText
                text="|"
                size="12px"
                family="Titillium Web"
                color="extsy.neutral800"
              />
              <Flex align="center" gap="4px">
                <CustomText text="2FA" size="12px" family="Titillium Web" />
                <Circle size="10px" bg="#10B981">
                  <IconCheck size={6} color="#fafafa" />
                </Circle>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          align={{ md: "center" }}
          gap={{ base: "16px", lg: "32px" }}
          mt={{ base: "8px", md: "0px" }}
          flexDir={{
            sm: "row",
            base: "column",
          }}
          w={{ base: "100%", sm: "auto" }}
        >
          <Flex flexDir="column" gap="16px">
            <Flex
              justify="space-between"
              align="center"
              justifyContent="space-between"
            >
              <CustomText
                text="P2P Estimated Value (BTC)"
                color="extsy.baseWhite"
              />
              <IconEyeOff size={21} color="#FEF8C3" />
            </Flex>
            <Flex align="flex-end" gap="12px">
              <CustomText
                text="0.00000000"
                size="32px"
                family="Titillium Web"
                color="extsy.secondary100"
              />
              <CustomText
                text="BTC ≈ $0.00"
                lh="22px"
                family="Titillium Web"
                color="extsy.neutral500"
              />
            </Flex>
          </Flex>
          <PrimaryButton title="Become Merchant" width={{ sm: "177px" }} />
        </Flex>
      </Flex>
      <Flex
        display={{
          base: "flex",
          lg: "none",
        }}
        px="16px"
        py="8px"
        borderRadius="16px"
        bg="extsy.neutral900"
        flexDir="column"
        // gap="8px"
      >
        <Flex py="8px" align="center" justify="space-between">
          <CustomText
            text={
              <>
                <span>0</span>{" "}
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  (Times)
                </span>
              </>
            }
            size="18px"
            weight="600"
            color="extsy.baseWhite"
          />
          <Flex align="center" gap="8px">
            <CustomText text="30days Trade" size="14px" />
            <IconInfoCircleFilled size={12} color="#BAB8B8" />
          </Flex>
        </Flex>
        <Flex py="8px" align="center" justify="space-between">
          <CustomText
            text={
              <>
                <span>0</span>{" "}
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  (%)
                </span>
              </>
            }
            size="18px"
            weight="600"
            color="extsy.baseWhite"
          />
          <Flex align="center" gap="8px">
            <CustomText text="30d Completion Rate" size="14px" />
            <IconInfoCircleFilled size={12} color="#BAB8B8" />
          </Flex>
        </Flex>
        <Flex py="8px" align="center" justify="space-between">
          <CustomText
            text={
              <>
                <span>0</span>{" "}
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  (Minutes)
                </span>
              </>
            }
            size="18px"
            weight="600"
            color="extsy.baseWhite"
          />
          <Flex align="center" gap="8px">
            <CustomText text="Avg. Release Time" size="14px" />
            <IconInfoCircleFilled size={12} color="#BAB8B8" />
          </Flex>
        </Flex>
        <Flex py="8px" align="center" justify="space-between">
          <CustomText
            text={
              <>
                <span>0</span>{" "}
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  (Minutes)
                </span>
              </>
            }
            size="18px"
            weight="600"
            color="extsy.baseWhite"
          />
          <Flex align="center" gap="8px">
            <CustomText text="Avg. Pay Time" size="14px" />
            <IconInfoCircleFilled size={12} color="#BAB8B8" />
          </Flex>
        </Flex>
        <Flex py="8px" align="center" justify="space-between">
          <CustomText
            text={
              <>
                <span>0</span>{" "}
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  (%)
                </span>
              </>
            }
            size="18px"
            weight="600"
            color="extsy.baseWhite"
          />
          <Flex align="center" gap="8px">
            <CustomText text="Positive Feedback" size="14px" />
            <IconInfoCircleFilled size={12} color="#BAB8B8" />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        wrap="wrap"
        gap="16px"
        display={{
          lg: "flex",
          base: "none",
        }}
      >
        <CustomCard title="0" subtitle="(Times)" description="30days Trade" />
        <CustomCard
          title="0"
          subtitle="(%)"
          description="30d Completion Rate"
        />
        <CustomCard
          title="0"
          subtitle="(Minutes)"
          description="Avg. Release Time"
        />
        <CustomCard
          title="0"
          subtitle="(Minutes)"
          description="Avg. Pay Time"
        />
        <CustomCard title="0" subtitle="(%)" description="Positive Feedback" />
      </Flex>
      <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={0}>
        <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
          {TABS?.map((tab, index) => (
            <Tab
              key={index}
              px="32px"
              pt="10px"
              pb="14px"
              w={{ base: "50%", md: "auto" }}
              color="extsy.secondary100"
            >
              <CustomText
                text={tab}
                color={
                  index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                }
                cursor="pointer"
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels p="0" mt={{ base: "16px", lg: "40px" }}>
          <TabPanel p="0">
            <PaymentMethods />
          </TabPanel>
          <TabPanel p="0" pos="relative">
            <Box
              pos="absolute"
              top="0"
              right="0"
              mt={{ base: "-45px", md: "-60px", lg: "-80px" }}
            >
              <PrimaryButton
                title="Post New Add"
                maxW="139px"
                height="36px"
                onClick={() => navigate("/p2p/post-new-add")}
              />
            </Box>
            <MyListing />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

const CustomCard = ({ title, subtitle, description }: any) => {
  const [isLessThan1684] = useMediaQuery("(max-width: 1684px)");
  return (
    <Flex
      flexDir="column"
      gap="16px"
      align="center"
      justify="center"
      p="24px"
      bg="extsy.neutral900"
      borderRadius="16px"
      border="1px solid"
      borderColor="extsy.neutral800"
      h="103px"
      minW="200px"
      maxW={isLessThan1684 ? "230px" : "270px"}
      w={isLessThan1684 ? "100%" : "270px"}
    >
      <CustomText
        text={
          <>
            <span>{title}</span>{" "}
            <span
              style={{
                fontSize: "14px",
              }}
            >
              {subtitle}
            </span>
          </>
        }
        size="18px"
        weight="600"
        color="extsy.baseWhite"
      />
      <Flex align="center" gap="8px">
        <CustomText text={description} size="14px" />
        <IconInfoCircleFilled size={12} color="#BAB8B8" />
      </Flex>
    </Flex>
  );
};

export default Seller;
