import {
  Avatar,
  Box,
  Circle,
  Flex,
  Image,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import { IconCheck, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { BUY_ORDERS } from "../../../utils/constants";
import ArrowBack from "../../../assets/images/black-left.svg";
import ArrowForward from "../../../assets/images/black-right.svg";
import GradientText from "../../../components/text/GradientText";
import PrimaryButton from "../../../components/button/PrimaryButton";
import CustomModal from "../../../components/modal/customModal";
import USDTIco from "../../../assets/images/usdt-ico.svg";
import CustomButton from "../../../components/button/CustomButton";

function BuyTable() {
  const { t } = useTranslation();

  const {
    isOpen: isBuyOpen,
    onOpen: onBuyOpen,
    onClose: onBuyClose,
  } = useDisclosure();

  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer
        minH="55vh"
        maxW={{ base: "1164px", "1xl": "100%" }}
        overflowX="auto"
      >
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Advertisers"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <CustomText
                    text={"(Completion rate)"}
                    size="13px"
                    color="extsy.neutral500"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Price"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Available/Limit"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Chain"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Payment Method"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {BUY_ORDERS.map((order, index) => (
              <Tr key={index}>
                <Td
                  height="64px"
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  py="0"
                >
                  <Flex gap="13px" align="center">
                    <Avatar src="" boxSize="32px" />
                    <Flex gap="10px" flexDir="column">
                      <Flex gap="8px">
                        <CustomText
                          text={order.user?.name}
                          size="14px"
                          color="extsy.baseWhite"
                        />
                        <Circle bg="#10B981" size="12px">
                          <IconCheck size="8px" color="#fafafa" />
                        </Circle>
                      </Flex>
                      <Flex gap="6px" align="center">
                        <CustomText
                          text={`${order?.user?.orders} Orders`}
                          size="12px"
                        />
                        <CustomText
                          text="|"
                          size="12px"
                          color="extsy.neutral900"
                        />
                        <CustomText
                          text={`${order?.user?.completion}% completion`}
                          size="12px"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" justify="space-between">
                    <CustomText text={order.price} size="14px" />
                    <CustomText
                      text={`USD`}
                      size="14px"
                      color="extsy.neutral700"
                    />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" gap="24px">
                    <Flex flexDir="column">
                      <CustomText
                        text={"Available"}
                        size="12px"
                        color="extsy.baseWhite"
                      />
                      <CustomText text={order.available} size="12px" />
                    </Flex>
                    <Flex flexDir="column">
                      <CustomText
                        text={"Limit"}
                        size="12px"
                        color="extsy.baseWhite"
                      />{" "}
                      <CustomText text={order.limit} size="12px" />
                    </Flex>
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText text={order.chain} size="14px" />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText text={order.method} size="14px" />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <PrimaryButton
                    title="Buy USDT"
                    maxW="120px"
                    height="28px"
                    size="12px"
                    onClick={onBuyOpen}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
      <CustomModal
        isOpen={isBuyOpen}
        onClose={() => onBuyClose()}
        onSubmit={() => {
          onBuyClose();
        }}
        noHeader
        noFooter
        width={"900px"}
        bgtopBlur="transparent"
        bg="extsy.neutral900"
        borderColor="extsy.neutral1000"
        bgFilter="blur(0px)"
      >
        <Flex p={{ base: "16px", lg: "32px" }} flexDir="column" gap="24px">
          <Flex gap="13px" align="center">
            <Avatar src="" boxSize="48px" />
            <Flex flexDir="column" gap="10px">
              <Flex gap="8px" align="center">
                <CustomText
                  text={"Advertiser Name"}
                  size="18px"
                  weight="600"
                  color="extsy.baseWhite"
                />
                <Circle bg="#10B981" size="12px">
                  <IconCheck size="8px" color="#fafafa" />
                </Circle>
              </Flex>
              <Flex gap="6px" align="center">
                <CustomText text={"10 Orders"} size="12px" />
                <CustomText text={"|"} size="12px" color="extsy.neutral700" />
                <CustomText text={"100% completion"} size="12px" />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            align={{ lg: "center" }}
            justify="space-between"
            p="16px"
            bg="extsy.neutral1000"
            flexDir={{
              base: "column",
              lg: "row",
            }}
            gap={{ base: "16px", lg: "0px" }}
            borderRadius={{ base: "16px", lg: "0px" }}
          >
            <Flex flexDir="column" gap="6px">
              <CustomText text="Price" size="12px" />
              <GradientText text="1.27 USDT" size="12px" bg="extsy.g1" />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Average Pay Time" size="12px" />
              <GradientText text="15 mins" size="12px" bg="extsy.g1" />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Available" size="12px" />
              <GradientText text="346.29 USDT" size="12px" bg="extsy.g1" />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Chain" size="12px" />
              <GradientText text="BTC" size="12px" bg="extsy.g1" />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Limit" size="12px" />
              <GradientText
                text="1,290.80 USDT - 5000.00 USDT"
                size="12px"
                bg="extsy.g1"
              />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Payment Method" size="12px" />
              <GradientText text="Bank" size="12px" bg="extsy.g1" />
            </Flex>
          </Flex>
          <Flex gap="40px" align="center" justify="space-between">
            <Flex
              flexDir="column"
              gap="12px"
              display={{ base: "none", lg: "flex" }}
            >
              <CustomText text={`Advertisers' Terms`} color="extsy.baseWhite" />
              <CustomText text={"🇰🇭 Only Banks in Cambodia"} size="12px" />
              <CustomText text="🇰🇭 Only Traders in Cambodia" size="12px" />
              <CustomText text="⛔️ No International Wire" size="12px" />
              <CustomText text="⛔️ No Visa Direct" size="12px" />
              <CustomText text="⛔️ International Wallet payment" size="12px" />
              <CustomText text="⛔️ No 3rd Party" size="12px" />
              <CustomText
                text="⛔️ No foreigner who rents Binance using other KYC name."
                size="12px"
              />
              <CustomText text="🔸 I will not release or return" size="12px" />
            </Flex>
            <Flex
              flexDir="column"
              gap="24px"
              p="16px"
              borderRadius="16px"
              bg="extsy.neutral1000"
              maxW="463px"
              w="100%"
            >
              <CustomText
                text="How many USDT you want to buy?"
                size="14px"
                weight="600"
                color="extsy.baseWhite"
              />
              <CustomText text="Price 1.05 USD" size="14px" color="#15B097" />
              <Flex flexDir="column" gap="12px">
                <Flex flexDir="column" gap="8px">
                  <CustomText text="You Pay" size="14px" />
                  <Flex
                    justify="space-between"
                    gap="12px"
                    px="16px"
                    align="center"
                    bg="extsy.neutral900"
                    borderRadius="8px"
                    cursor={"pointer"}
                    h="40px"
                  >
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily="Metropolis"
                      placeholder="0"
                      type="number"
                      h="40px"
                      pl="0"
                      py="0"
                      border="none"
                      _placeholder={{ color: "extsy.neutral300" }}
                      _hover={{ border: "none" }}
                      _focus={{
                        border: "none",
                      }}
                      _focusVisible={{ border: "none" }}
                    />
                    <Flex align="center" gap="8px">
                      <CustomText text="USD" size="12px" />
                      <CustomText
                        text="|"
                        size="12px"
                        color="extsy.neutral700"
                      />
                      <GradientText
                        text="All"
                        size="12px"
                        bg="extsy.g1"
                        cursor="pointer"
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex flexDir="column" gap="8px">
                  <CustomText text="You Get" size="14px" />
                  <Flex
                    justify="space-between"
                    gap="12px"
                    px="16px"
                    align="center"
                    bg="extsy.neutral900"
                    borderRadius="8px"
                    cursor={"pointer"}
                    h="40px"
                  >
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily="Metropolis"
                      placeholder="0"
                      type="number"
                      h="40px"
                      pl="0"
                      py="0"
                      border="none"
                      _placeholder={{ color: "extsy.neutral300" }}
                      _hover={{ border: "none" }}
                      _focus={{
                        border: "none",
                      }}
                      _focusVisible={{ border: "none" }}
                    />
                    <Flex align="center" gap="8px">
                      <Avatar src={USDTIco} boxSize="16px" />
                      <CustomText text="USDT" size="12px" />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex align="center" gap="16px">
                <CustomButton
                  title="Cancel"
                  bg="extsy.neutral900"
                  color="extsy.baseWhite"
                  height="40px"
                  size="12px"
                  onClick={onBuyClose}
                />
                <PrimaryButton
                  title="Place Order"
                  height="40px"
                  size="12px"
                  onClick={onBuyClose}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default BuyTable;
