import Login from "../pages/auth/Login";
import SecurityVerification from "../pages/auth/SecurityVerification";
import LoginSecurityCode from "../pages/auth/LoginSecurityCode";
import ForgotPassword from "../pages/auth/forgotPassword";
import VerifyEmail from "../pages/auth/VerifyEmail";
import EnterEmail from "../pages/auth/EnterEmail";
import SignUp from "../pages/auth/SignUp";
import Dashboard from "../pages/dashboard";
import UpdatePassword from "../pages/auth/UpdatePassword";
import VirtualCard from "../pages/virtualCard";
import Swap from "../pages/swap";
import BalanceTracker from "../pages/balanceTracker";
import ExtsyPro from "../pages/extsyPro";
import Trading from "../pages/trading";
import TradingBalance from "../pages/tradingBalance";
import TransactionHistory from "../pages/transactionHistory";
import AmlCheck from "../pages/amlCheck";
import Raffle from "../pages/raffle";
import ComingSoon from "../pages/comingSoon";
import Settings from "../pages/settings";
import P2P from "../pages/p2p";
import PostNewAdd from "../pages/postNewAdd";

export const routes = [
  {
    path: "coming-soon",
    component: ComingSoon,
    isPrivate: false,
  },
  {
    path: "login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "signup",
    component: SignUp,
    isPrivate: false,
  },
  {
    path: "verification-code",
    component: SecurityVerification,
    isPrivate: false,
  },
  {
    path: "security-code",
    component: LoginSecurityCode,
    isPrivate: false,
  },
  {
    path: "forgot-password",
    component: ForgotPassword,
    isPrivate: false,
    children: [
      {
        path: "enter-email",
        component: EnterEmail,
      },
      {
        path: "verify-email",
        component: VerifyEmail,
      },
      {
        path: "update-password",
        component: UpdatePassword,
      },
    ],
  },
  {
    path: "/",
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/swap",
    component: Swap,
    isPrivate: true,
  },
  {
    path: "/virtual-card",
    component: VirtualCard,
    isPrivate: true,
  },
  {
    path: "/balance-tracker",
    component: BalanceTracker,
    isPrivate: true,
  },
  {
    path: "/extsy-pro",
    component: ExtsyPro,
    isPrivate: true,
  },
  {
    path: "/trading",
    component: Trading,
    isPrivate: true,
  },
  {
    path: "/trading-balance",
    component: TradingBalance,
    isPrivate: true,
  },
  {
    path: "/transaction-history",
    component: TransactionHistory,
    isPrivate: true,
  },
  {
    path: "/aml-check",
    component: AmlCheck,
    isPrivate: true,
  },
  {
    path: "/raffle",
    component: Raffle,
    isPrivate: true,
  },
  {
    path: "/settings",
    component: Settings,
    isPrivate: true,
  },
  {
    path: "/p2p",
    component: P2P,
    isPrivate: true,
  },
  {
    path: "/p2p/post-new-add",
    component: PostNewAdd,
    isPrivate: true,
  },
];
