import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import PlusIcoYellow from "../../assets/images/plus-yellow.svg";
import TradingInput from "../form/TradingInput";
import CustomTradingSlider from "../form/CustomTradingSlider";
import CustomCheckbox from "../form/CustomCheckbox";
import CustomButton from "../button/CustomButton";

function BuySell() {
  const [activeTab, setActiveTab] = useState("Limit");
  const [isBuyChecked, setIsBuyChecked] = useState(false);

  return (
    <Box p={{ base: "16px", lg: "16px 24px 24px 24px" }}>
      <Flex align="center" gap="21px">
        <CustomText
          text="Limit"
          size="16.6px"
          cursor="pointer"
          color={activeTab === "Limit" ? "#F0B90B" : "extsy.neutral400"}
          onClick={() => setActiveTab("Limit")}
        />

        <CustomText
          text="Market"
          size="16.6px"
          cursor="pointer"
          color={activeTab === "Market" ? "#F0B90B" : "extsy.neutral400"}
          onClick={() => setActiveTab("Market")}
        />
      </Flex>
      <Flex
        mt="11px"
        gap="32px"
        flexDir={{
          base: "column",
          lg: "row",
        }}
      >
        <Flex
          gap="16px"
          flexDir="column"
          justify="space-between"
          width="100%"
          minH={{ lg: "422px" }}
        >
          <Flex gap="16px" flexDir="column">
            <Flex gap="8px" align="center">
              <CustomText text="Avbl" size="14.3px" color="extsy.neutral400" />
              <CustomText
                text="340.41778774 USDT"
                size="13.2px"
                color="extsy.text2"
              />
              <Image
                src={PlusIcoYellow}
                alt="add"
                height="14px"
                width="14px"
                cursor="pointer"
              />
            </Flex>
            <TradingInput label="Price" currency="USDT" />
            <TradingInput label="Amount" currency="BTC" />
            <CustomTradingSlider />
            {activeTab === "Limit" && (
              <TradingInput label="Total" currency="USDT" />
            )}{" "}
            <Flex gap="8px" align="center">
              <CustomText text="Max Buy" size="14px" color="extsy.neutral400" />
              <CustomText
                text="0 BTC"
                size="13px"
                color="extsy.text2"
                mt="2px"
              />
            </Flex>
            <CustomText text="Est Fee" size="14px" color="extsy.neutral400" />
            {activeTab === "Limit" && (
              <CustomCheckbox
                isChecked={isBuyChecked}
                setChecked={setIsBuyChecked}
                label="TP/SL"
              />
            )}
          </Flex>
          <CustomButton
            title="Buy BTC"
            height="48px"
            radius="5px"
            bg="#0ECB81"
            color="extsy.white"
          />
        </Flex>
        <Flex
          gap="16px"
          flexDir="column"
          justify="space-between"
          width="100%"
          minH={{ lg: "422px" }}
        >
          <Flex flexDir="column" gap="16px">
            <Flex gap="8px" align="center">
              <CustomText text="Avbl" size="14.3px" color="extsy.neutral400" />
              <CustomText
                text="0.41778774 BTC"
                size="13.2px"
                color="extsy.text2"
              />
              <Image
                src={PlusIcoYellow}
                alt="add"
                height="14px"
                width="14px"
                cursor="pointer"
              />
            </Flex>
            <TradingInput label="Price" currency="BTC" />
            <TradingInput label="Amount" currency="BTC" />
            <CustomTradingSlider />
            {activeTab === "Limit" && (
              <TradingInput label="Total" currency="BTC" />
            )}
            <Flex gap="8px" align="center">
              <CustomText text="Max Buy" size="14px" color="extsy.neutral400" />
              <CustomText
                text="0 BTC"
                size="13px"
                color="extsy.text2"
                mt="2px"
              />
            </Flex>
            <CustomText text="Est Fee" size="14px" color="extsy.neutral400" />
            {activeTab === "Limit" && (
              <CustomCheckbox
                isChecked={isBuyChecked}
                setChecked={setIsBuyChecked}
                label="TP/SL"
              />
            )}
          </Flex>
          <CustomButton
            title="Sell BTC"
            height="48px"
            radius="5px"
            bg="#F6465D"
            color="extsy.white"
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default BuySell;
