import { Flex, useMediaQuery, Img } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import SecurityImg from "../../assets/images/auth-security-Img.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import CustomVerificationInput from "../../components/form/customVerificationInput";
import CustomModal from "../../components/modal/customModal";
import IdentityImg from "../../assets/images/identityImg.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function LoginSecurityCode() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const [open, setOpen] = useState(false);

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Flex
        px={{ base: "16px", md: "40px" }}
        py={isLessThan768 ? "40px" : "56px"}
        mx="auto"
        bg="rgba(255, 255, 255, 0.03)"
        borderRadius="24px"
        maxW="480px"
        w="100%"
        flexDir="column"
        justify="space-between"
        gap={isLessThan768 ? "24px" : "0px"}
        minH={isLessThan768 ? "524px" : "742px"}
      >
        <Flex align="center" flexDir="column" gap="24px">
          <CustomText
            text={t("enterSecurityCode")}
            family="Titillium Web"
            size="24px"
            lh="28px"
            weight="700"
            align="center"
            color="extsy.baseWhite"
          />
          <CustomText
            text={t("securityCodeDescription")}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>

        <Flex flexDir="column" align="center" justify="center">
          <Img src={SecurityImg} alt="img" minW={"139px"} minH={"120px"} />
        </Flex>
        <Flex flexDir="column" align="center" justify="center">
          <CustomVerificationInput placeholder={0} />
        </Flex>
        <Flex align="center">
          <PrimaryButton title={t("verify")} onClick={handleModalOpen} />{" "}
        </Flex>
      </Flex>

      <CustomModal
        isOpen={open}
        onClose={handleModalClose}
        onSubmit={() => navigate("/")}
        height={"450px"}
      >
        <Flex
          flexDir="column"
          align="center"
          gap={"32px"}
          minH="100%"
          px="24px"
          py="32px"
        >
          <Img src={IdentityImg} alt="checkicon" minH="104px" minW="67.69px" />
          <CustomText
            text={t("verifyYourIdentity")}
            color="extsy.baseWhite"
            lh="19.2px"
            size="24px"
            family="Titillium Web"
            pb="8px"
          />
          <CustomText
            text={t("verificationDescription")}
            color="extsy.neutral300"
            align="center"
            lh="19.2px"
          />
        </Flex>
      </CustomModal>
    </>
  );
}

export default LoginSecurityCode;
