import { Box, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import SocialLoginButton from "../../components/button/SocialLoginButton";
import GoogleIcon from "../../assets/images/google-ico.svg";
import AppleIcon from "../../assets/images/apple-ico.svg";
import CustomInput from "../../components/form/CustomInput";
import EyeIcon from "../../assets/images/eye-ico.svg";
import GradientText from "../../components/text/GradientText";
import PrimaryButton from "../../components/button/PrimaryButton";
import { SignUpPayload } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const [payload, setPayload] = useState<SignUpPayload>({
    email: "",
    password: "",
    name: "",
    referral: "",
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <Flex
      px={{ base: "16px", md: "40px" }}
      py={isLessThan768 ? "24px" : "56px"}
      mx="auto"
      bg="rgba(255, 255, 255, 0.03)"
      borderRadius={{ base: "16px", md: "24px" }}
      maxW="480px"
      w="100%"
      flexDir="column"
      justify="space-between"
      gap="24px"
      minH={isLessThan768 ? "424px" : "742px"}
    >
      <Box>
        <Flex align="center" flexDir="column" gap="32px">
          <CustomText
            text={t("signUp")}
            family="Titillium Web"
            size="24px"
            lh="32px"
            weight="700"
            align="center"
            color="extsy.baseWhite"
          />
          <CustomText
            text={t("joinExtsyPro")}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
        <Flex
          align="center"
          flexDir="column"
          mt={{ base: "24px", md: "40px" }}
          gap="16px"
        >
          <CustomText
            text={t("continueWith")}
            color="extsy.neutral500"
            size="14px"
          />
          <Flex align="center" gap="16px">
            <SocialLoginButton icon={GoogleIcon} title="Google" />
            <SocialLoginButton icon={AppleIcon} title="Apple" />
          </Flex>
          <Flex align="center" justify="center" gap="16px" w="100%">
            <Box bg="extsy.neutral900" height="1px" maxW="170px" w="100%" />
            <Box>
              <CustomText
                text={t("or")}
                size="14px"
                lh="14px"
                color="extsy.neutral500"
                wrap="nowrap"
                width="120px"
              />
            </Box>
            <Box bg="extsy.neutral900" height="1px" maxW="170px" w="100%" />
          </Flex>
        </Flex>
      </Box>
      <Box>
        <Flex flexDir="column" gap="24px">
          <Flex flexDir="column">
            <CustomText text={t("name")} color="extsy.neutral500" size="12px" />
            <CustomInput
              placeholder={t("enterName")}
              type="text"
              value={payload.name}
              onChange={(e: any) =>
                setPayload({ ...payload, name: e.target.value })
              }
            />
          </Flex>
          <Flex flexDir="column">
            <CustomText
              text={t("email")}
              color="extsy.neutral500"
              size="12px"
            />
            <CustomInput
              placeholder={t("enteryourEmail")}
              type="email"
              value={payload.email}
              onChange={(e: any) =>
                setPayload({ ...payload, email: e.target.value })
              }
            />
          </Flex>
          <Flex flexDir="column">
            <CustomText
              text={t("password")}
              color="extsy.neutral500"
              size="12px"
            />
            <CustomInput
              placeholder="**************"
              value={payload.password}
              onChange={(e: any) =>
                setPayload({ ...payload, password: e.target.value })
              }
              type={isPasswordVisible ? "text" : "password"}
              rightIco={
                <Image
                  src={EyeIcon}
                  alt="passwordEye"
                  cursor="pointer"
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                />
              }
            />
          </Flex>
          <Flex flexDir="column">
            <CustomText
              text={t("referralCode")}
              color="extsy.neutral500"
              size="12px"
            />
            <CustomInput
              placeholder={t("enterReferralCode")}
              type="text"
              value={payload.referral}
              onChange={(e: any) =>
                setPayload({ ...payload, referral: e.target.value })
              }
            />
          </Flex>
        </Flex>
      </Box>
      <Flex flexDir="column" gap="24px" align="center">
        <PrimaryButton
          title={t("signUp")}
          onClick={() => {
            navigate("/auth/verification-code");
          }}
        />
        <Flex align="center" gap="4px">
          <CustomText
            text={t("alreadyHaveAccount")}
            color="extsy.neutral300"
            lh="19px"
          />
          <GradientText
            text={t("signIn")}
            weight="600"
            bg="extsy.g1"
            lh={{ md: "22px" }}
            cursor="pointer"
            onClick={() => navigate("/auth/login")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
