import {
  Box,
  Flex,
  Image,
  Slider,
  SliderFilledTrack,
  SliderTrack,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import PrimeIcon from "../../assets/images/prime-ico.svg";

function CheckCounter() {
  return (
    <Flex
      minH={{ lg: "406px" }}
      width="100%"
      p={{ base: "16px", lg: "24px" }}
      flexDir="column"
      gap={{ base: "16px", lg: "40px" }}
      justify="space-between"
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral900"
      bg="extsy.neutral1000"
      pos="relative"
      overflow="hidden"
    >
      <Flex gap={{ base: "16px", lg: "40px" }} flexDir="column" zIndex="100">
        <CustomText
          text="Check Counter"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          family="Titillium Web"
          color="extsy.baseWhite"
        />
        <Flex flexDir="column" gap="16px">
          <CustomText
            text="Your current Level"
            size="14px"
            color="extsy.baseWhite"
          />
          <Flex
            gap="12px"
            cursor="pointer"
            px="16px"
            height={{ base: "38px", lg: "56px" }}
            w="128px"
            align="center"
            justify="center"
            borderRadius="99px"
            bg="rgba(250, 250, 250, 0.10)"
          >
            <Image
              src={PrimeIcon}
              alt="prime ico"
              height={{ lg: "20px" }}
              width={{ lg: "23px" }}
            />
            <CustomText
              text="Prime"
              size={{ lg: "22px" }}
              color="extsy.baseWhite"
            />
          </Flex>
          <CustomText
            text="Check Counter"
            size="12px"
            color="extsy.baseWhite"
            family="Titillium Web"
          />
          <CustomText
            text={
              <>
                <span
                  style={{
                    fontWeight: 600,
                    color: "#fafafa",
                  }}
                >
                  0{" "}
                </span>
                <span>check of 1</span>
              </>
            }
            size="16px"
          />
          <Slider value={0}>
            <SliderTrack height="8px" bg="extsy.neutral700" borderRadius="99px">
              <SliderFilledTrack bg="extsy.baseWhite" />
            </SliderTrack>
          </Slider>
        </Flex>
      </Flex>
      <Flex
        gap="16px"
        align={{ lg: "center" }}
        zIndex="100"
        flexDir={{
          lg: "row",
          base: "column",
        }}
      >
        <PrimaryButton
          title="Upgrade to Supreme"
          maxW={{ lg: "272px" }}
          order={{ lg: 1, base: 2 }}
        />
        <Flex
          flexDir={{ lg: "column" }}
          gap="4px"
          align={{ base: "center", lg: "flex-start" }}
          justify={{ base: "space-between", lg: "auto" }}
          order={{ lg: 2, base: 1 }}
        >
          <CustomText
            text="For"
            size="14px"
            color="extsy.baseWhite"
            family="Titilium Web"
          />
          <CustomText
            text={
              <>
                <span>$15.00</span>
                <span
                  style={{
                    fontSize: "14px",
                    color: "#BAB8B8",
                  }}
                >
                  /month
                </span>
              </>
            }
            size="24px"
            weight="600"
            color="extsy.baseWhite"
          />
        </Flex>
      </Flex>
      <Box
        height="447px"
        width="447px"
        borderRadius="447px"
        opacity="0.5"
        bg="extsy.primary500"
        filter="blur(82px)"
        pos="absolute"
        top="0"
        right="0"
        zIndex="1"
        mt="-340px"
        mr="-258px"
      />
    </Flex>
  );
}

export default CheckCounter;
