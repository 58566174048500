import {
  Box,
  Circle,
  Flex,
  Image,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import TradingBg from "../../assets/images/trading-bg.svg";
import PrimaryButton from "../button/PrimaryButton";
import CustomButton from "../button/CustomButton";
import {
  IconArrowDownLeft,
  IconArrowRight,
  IconArrowUpRight,
  IconHexagonFilled,
} from "@tabler/icons-react";
import CustomModal from "../modal/customModal";
import CustomSelect from "../form/CustomSelect";
import VCardInput from "../form/vCardInput";
import USDTIco from "../../assets/images/usdt-ico.svg";
import IconSwap from "../../assets/images/swap-icon.svg";

function TradingEstimateBalanceCard({ p2p }: any) {
  const [isSpotFuture, setIsSpotFuture] = useState(true);

  const [isLessThan992] = useMediaQuery("(max-width: 992px)");

  const {
    isOpen: isWithdrawOpen,
    onOpen: onWithdrawOpen,
    onClose: onWithdrawClose,
  } = useDisclosure();
  const {
    isOpen: isTransferOpen,
    onOpen: onTransferOpen,
    onClose: onTransferClose,
  } = useDisclosure();
  const {
    isOpen: isDepositOpen,
    onOpen: onDepositOpen,
    onClose: onDepositClose,
  } = useDisclosure();

  return (
    <Flex
      justify="space-between"
      align="center"
      overflow="hidden"
      p={{ base: "16px", lg: "40px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      pos="relative"
      flexDir={{ base: "column", lg: "row" }}
      gap={{ base: "32px", lg: "16px" }}
    >
      <Flex
        flexDir="column"
        align={{ base: "center", lg: "flex-start" }}
        gap={{ base: "12px", lg: "23px" }}
        zIndex="1000"
      >
        <CustomText text="Estimated Balance" color="extsy.baseWhite" />
        <Flex gap="12px" align="flex-end">
          <CustomText
            text="20.0400799"
            size={{ base: "32px", lg: "56px" }}
            family="Titillium Web"
            color="extsy.secondary100"
            ls="2.8px"
          />
          <CustomText
            text="BTC"
            size="24px"
            color="extsy.neutral500"
            family="Titillium Web"
            ls="1.2px"
            lh={{ base: "24px", lg: "34px" }}
          />
        </Flex>
        <Flex gap="10px" align="flex-end">
          <CustomText
            text="Today’s PnL"
            family="Titillium Web"
            color="extsy.baseWhite"
            lh="22px"
          />
          <CustomText
            text="+$0.00(0.17%)"
            color="#0ECB81"
            family="Titillium Web"
            lh="22px"
          />
        </Flex>
      </Flex>
      <Flex gap="16px" align="center" w="100%" zIndex="1000" justify="flex-end">
        <PrimaryButton
          title="Deposit"
          maxW="159px"
          height={{ base: "38px", lg: "56px" }}
          size={{ base: "12px", lg: "16px" }}
          onClick={onDepositOpen}
          icon={
            <IconArrowDownLeft
              size={isLessThan992 ? "18px" : "24px"}
              color="#0B0A0A"
            />
          }
        />
        <CustomButton
          title="Withdraw"
          maxW="174px"
          height={{ base: "38px", lg: "56px" }}
          size={{ base: "12px", lg: "16px" }}
          icon={
            <IconArrowUpRight
              size={isLessThan992 ? "18px" : "24px"}
              color="#0B0A0A"
            />
          }
          onClick={onWithdrawOpen}
        />
        {!p2p && (
          <CustomButton
            title="Transfer"
            maxW="164px"
            height={{ base: "38px", lg: "56px" }}
            size={{ base: "12px", lg: "16px" }}
            onClick={onTransferOpen}
            icon={
              <IconArrowRight
                size={isLessThan992 ? "18px" : "24px"}
                color="#0B0A0A"
              />
            }
          />
        )}
      </Flex>
      <Box
        height="419px"
        width="419px"
        borderRadius="419px"
        bg="#15B097"
        filter="blur(92px)"
        pos="absolute"
        zIndex="1"
        top="0"
        left="0"
        mt="-319px"
        ml={{ base: "-273px", lg: "-73px" }}
      />
      <Image
        src={TradingBg}
        alt="bg"
        pos="absolute"
        right="0"
        filter="blur(92px)"
        zIndex="1"
      />
      <CustomModal
        isOpen={isWithdrawOpen}
        onClose={onWithdrawClose}
        onSubmit={() => {
          onWithdrawClose();
        }}
        headerText={"Withdraw"}
        btnText={"Withdraw"}
        closeBtntext={"Cancel"}
        width={"700px"}
        bgtopBlur="extsy.bgCardTopBlur"
        blurTop="-308px"
      >
        <Flex
          p={{ base: "16px", lg: "32px" }}
          flexDir="column"
          gap={{ base: "16px", lg: "32px" }}
          w="100%"
        >
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <CustomSelect placeholder="USDT" ico={USDTIco} />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Address" size="14px" />
            <VCardInput placeholder="0x000.00000" bg="extsy.neutral900" />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Amount" size="14px" />
            <VCardInput placeholder="0.00" bg="extsy.neutral900" />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isTransferOpen}
        onClose={onTransferClose}
        onSubmit={() => {
          onTransferClose();
        }}
        headerText={"Transfer"}
        btnText={"Transfer"}
        closeBtntext={"Cancel"}
        width={"800px"}
        bgtopBlur="transparent"
      >
        <Flex
          p={{ base: "16px", lg: "24px" }}
          flexDir="column"
          gap={{ base: "24px", lg: "32px" }}
          w="100%"
        >
          <Flex
            align="center"
            justify="space-between"
            minH={{ lg: "150px" }}
            p={{ base: "16px", lg: "24px" }}
            borderRadius="24px"
            bg="extsy.bg"
            pos="relative"
            overflow="hidden"
          >
            <Flex
              zIndex="1000"
              flexDir="column"
              gap="16px"
              maxW="120px"
              w="100%"
            >
              <CustomText
                text="From"
                size="14px"
                transform="uppercase"
                weight="600"
                color="extsy.baseWhite"
              />
              {isSpotFuture ? (
                <SpotFlex isSpotFurute={isSpotFuture} />
              ) : (
                <FutureFlex isSpotFurute={isSpotFuture} />
              )}
            </Flex>
            <Image
              src={IconSwap}
              alt="swap"
              cursor="pointer"
              zIndex="1000"
              onClick={() => {
                setIsSpotFuture(!isSpotFuture);
              }}
            />

            <Flex
              flexDir="column"
              zIndex="1000"
              gap="16px"
              maxW="120px"
              w="100%"
            >
              <CustomText
                text="To"
                size="14px"
                transform="uppercase"
                weight="600"
                color="extsy.baseWhite"
                align="end"
              />
              {isSpotFuture ? (
                <FutureFlex isSpotFurute={isSpotFuture} />
              ) : (
                <SpotFlex isSpotFurute={isSpotFuture} />
              )}
            </Flex>
            <Box
              h="220px"
              w="220px"
              borderRadius="220px"
              bg="extsy.primary500"
              opacity="0.4"
              filter="blur(97px)"
              pos="absolute"
              bottom="0"
              mb="-55.5px"
              ml="266px"
              zIndex="1"
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <CustomSelect placeholder="USDT" ico={USDTIco} />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <Flex justify="space-between" align="center">
              <CustomText text="Amount" size={{ base: "12px", lg: "14px" }} />
              <CustomText
                text={
                  <>
                    <span>0.00000000 available</span>
                    <span
                      style={{
                        color: "#383434",
                      }}
                    >
                      {" "}
                      /{" "}
                    </span>
                    <span>0.00000000 in order</span>
                  </>
                }
                size={{ base: "12px", lg: "14px" }}
                color="extsy.baseWhite"
              />
            </Flex>
            <VCardInput placeholder="0.00" bg="extsy.neutral900" maxW="100%" />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isDepositOpen}
        onClose={onDepositClose}
        onSubmit={() => {
          onDepositClose();
        }}
        headerText={"Deposit"}
        btnText={"Deposit"}
        closeBtntext={"Cancel"}
        width={"700px"}
        bgtopBlur="transparent"
      >
        <Flex
          p={{ base: "16px", lg: "32px 24px" }}
          flexDir="column"
          gap="32px"
          w="100%"
        >
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <CustomSelect placeholder="USDT" ico={USDTIco} />
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

const SpotFlex = ({ isSpotFurute }: any) => {
  return (
    <Flex
      align="center"
      gap={{ base: "8px", lg: "24px" }}
      justify={isSpotFurute ? "flex-start" : "flex-end"}
    >
      {!isSpotFurute && <Circle size="18px" bg="extsy.primary" />}
      <CustomText
        text="Spot"
        size={{ base: "18px", lg: "22px" }}
        weight="600"
        color="extsy.primary500"
      />
      {isSpotFurute && <Circle size="18px" bg="extsy.primary" />}
    </Flex>
  );
};

const FutureFlex = ({ isSpotFurute }: any) => {
  return (
    <Flex
      align="center"
      gap={{ base: "8px", lg: "24px" }}
      justify={!isSpotFurute ? "flex-start" : "flex-end"}
    >
      {isSpotFurute && <IconHexagonFilled size="18px" color="#C4FFF5" />}
      <CustomText
        text="Future"
        size={{ base: "18px", lg: "22px" }}
        weight="600"
        color="#15B097"
        order={isSpotFurute ? 0 : 1}
      />
      {!isSpotFurute && <IconHexagonFilled size="18px" color="#C4FFF5" />}
    </Flex>
  );
};

export default TradingEstimateBalanceCard;
