import BitcoinIco from "../assets/images/btc-ico.svg";
import EthIco from "../assets/images/eth-ico.svg";
import BnbIco from "../assets/images/bnb-ico.svg";
import SolIco from "../assets/images/sol-ico.svg";
import UsdtIco from "../assets/images/usdt-ico.svg";
import XrpIco from "../assets/images/xrp-ico.svg";
import USDIco from "../assets/images/usd-ico.svg";
import BahDinarIco from "../assets/images/bah-dinar-ico.svg";
import YuanIco from "../assets/images/yuan-ico.svg";
import AudIco from "../assets/images/aud-ico.svg";
import OrderSS from "../assets/images/order-receipt.svg";

import {
  IconArrowForwardUp,
  IconCash,
  IconChartLine,
  IconCoin,
  IconCreditCard,
  IconCrown,
  IconExchange,
  IconHistoryToggle,
  IconLayoutGrid,
  IconMessageCircle,
  IconSettings,
  IconShieldCheck,
  IconTicket,
  IconTransform,
} from "@tabler/icons-react";

export const DASHBOARD_MENU = [
  {
    path: "/",
    name: "Dashboard",
    key: "dashboard",
    icon: IconLayoutGrid,
  },
  {
    path: "/swap",
    name: "Swap",
    key: "swap",
    icon: IconExchange,
  },
  {
    path: "/virtual-card",
    name: "Virtual Card",
    key: "virtualCard",
    icon: IconCreditCard,
  },
  {
    path: "/trading",
    name: "Trading",
    key: "trading",
    icon: IconChartLine,
  },
  {
    path: "/balance-tracker",
    name: "Balance Tracker",
    key: "balanceTracker",
    icon: IconCash,
  },
  {
    path: "/extsy-pro",
    name: "Extsy Pro",
    key: "extsyPro",
    icon: IconCrown,
  },
  {
    path: "/transaction-history",
    name: "Transaction History",
    key: "transactionHistory",
    icon: IconHistoryToggle,
  },
  {
    path: "/aml-check",
    name: "AML Check",
    key: "amlCheck",
    icon: IconShieldCheck,
  },
  {
    path: "/raffle",
    name: "Raffle",
    key: "raffle",
    icon: IconTicket,
  },
  {
    path: "/p2p",
    name: "P2P",
    key: "p2p",
    icon: IconTransform,
  },
];

export const DASHBOARD_OTHER_MENU = [
  {
    path: "/trading-balance",
    name: "Trading Balance",
    key: "tradingBalance",
    icon: IconCoin,
  },
  {
    path: "/settings",
    name: "Settings",
    key: "settings",
    icon: IconSettings,
  },
  {
    path: "/chat",
    name: "Support",
    key: "support",
    icon: IconMessageCircle,
  },
  {
    name: "Logout",
    key: "logout",
    icon: IconArrowForwardUp,
  },
];

export const AUTH_SLIDES_CONTENT = [
  {
    title: "Swap, Buy, Sell",
    description:
      "Swap, Buy, Sell, and Trade crypto assets effortlessly with our transparent, user-friendly platform. Enjoy seamless transactions, real-time data, and make informed decision-making.",
  },
  {
    title: "Extsy Pro",
    description:
      "Sign up for a free account to access a host of exclusive benefits and maximize your financial potential. Enjoy features designed to enhance your trading experience and optimize your returns, all while keeping your funds secure and under your control.",
  },
  {
    title: "Trading",
    description:
      "Enjoy tools designed to enhance your trading experience and optimize your returns, all while ensuring your portfolio remains secure and under your control.",
  },
];

export const CRYPTO_CURRENCIES = [
  {
    symbol: "BTC",
    name: "Bitcoin",
    icon: BitcoinIco,
    price: "$56,623.54",
  },
  {
    symbol: "ETH",
    name: "Ethereum",
    icon: EthIco,
    price: "$3,456.54",
  },
  {
    symbol: "BNB",
    name: "Binance",
    icon: BnbIco,
    price: "$456.54",
  },
  {
    symbol: "SOL",
    name: "Solana",
    icon: SolIco,
    price: "$156.54",
  },
  {
    symbol: "USDT",
    name: "Tether",
    icon: UsdtIco,
    price: "$1.00",
  },
  {
    symbol: "XRP",
    name: "Ripple",
    icon: XrpIco,
    price: "$1.00",
  },
];

export const FIAT_CURRENCIES = [
  {
    symbol: "USD",
    name: "US Dollar",
    icon: USDIco,
  },
  {
    symbol: "BHD",
    name: "Bahraini Dinar",
    icon: BahDinarIco,
  },
  {
    symbol: "CNY",
    name: "Chinese Yuan",
    icon: YuanIco,
  },
  {
    symbol: "AUD",
    name: "Australian Dollar",
    icon: AudIco,
  },
];

export const RECENT_TRANSACTIONS = [
  {
    id: "0x000....00000",
    amount: "-$639.930",
    type: "Buy",
    date: "23 Jul, 2024",
    status: "Completed",
  },
  {
    id: "0x000....00000",
    amount: "-$639.930",
    type: "Card Top-up",
    date: "22 Jul, 2024",
    status: "In Process",
  },
  {
    id: "0x000....00000",
    amount: "+$639.930",
    type: "Sell",
    date: "17 Jul, 2024",
    status: "Cancel",
  },
  {
    id: "0x000....00000",
    amount: "$639.930",
    type: "Swap",
    date: "23 Jul, 2024",
    status: "Completed",
  },
  {
    id: "0x000....00000",
    amount: "-$639.930",
    type: "Card Activation Fee",
    date: "06 Jul, 2024",
    status: "In Process",
  },
  {
    id: "0x000....00000",
    amount: "+$639.930",
    type: "Swap",
    date: "10 Jul, 2024",
    status: "Cancel",
  },
  {
    id: "0x000....00000",
    amount: "+$639.930",
    type: "Fee",
    date: "06 Jul, 2024",
    status: "Completed",
  },
];

export const BALANCES = [
  {
    icon: BitcoinIco,
    symbol: "BTC",
    name: "Bitcoin",
    usdValue: "0 USD",
    percentage: "-28%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
  },
  {
    icon: EthIco,
    symbol: "ETH",
    name: "Ethereum",
    usdValue: "0 USD",
    percentage: "12%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
  },
  {
    icon: BnbIco,
    symbol: "BNB",
    name: "Binance",
    usdValue: "0 USD",
    percentage: "-12%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
  },
  {
    icon: SolIco,
    symbol: "SOL",
    name: "Solana",
    usdValue: "0 USD",
    percentage: "8%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
  },
  {
    icon: UsdtIco,
    symbol: "USDT",
    name: "Tether",
    usdValue: "0 USD",
    percentage: "32%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
  },
  {
    icon: XrpIco,
    symbol: "XRP",
    name: "Ripple",
    usdValue: "0 USD",
    percentage: "-6%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
  },
];

export const PAIRS = [
  {
    name: "ANT",
    price: "0.00010256",
    change: "-1.82%",
  },

  {
    name: "ETH",
    price: "0.00010256",
    change: "-0.34%",
  },
  {
    name: "BNB",
    price: "0.00010256",
    change: "+0.35%",
  },
  {
    name: "SOL",
    price: "0.00010256",
    change: "+1.30%",
  },
  {
    name: "USDT",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "XRP",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "ANT",
    price: "0.00010256",
    change: "+10.38%",
  },
  {
    name: "ETH",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "BNB",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "SOL",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "USDT",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "XRP",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "SOL",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "USDT",
    price: "0.00010256",
    change: "-1.82%",
  },
  {
    name: "XRP",
    price: "0.00010256",
    change: "-1.82%",
  },
];

export const COINS = [
  {
    icon: BitcoinIco,
    name: "Bitcoin",
    symbol: "BTC",
  },
  {
    icon: EthIco,
    name: "Ethereum",
    symbol: "ETH",
  },
  {
    icon: BnbIco,
    name: "Binance",
    symbol: "BNB",
  },
  {
    icon: SolIco,
    name: "Solana",
    symbol: "SOL",
  },
  {
    icon: UsdtIco,
    name: "Tether",
    symbol: "USDT",
  },
  {
    icon: XrpIco,
    name: "Ripple",
    symbol: "XRP",
  },
];

export const MY_RAFFLES = [
  {
    id: "287",
    isActive: true,
    tickets: [
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
    ],
    createdAt: "17-08-2024 08:26 PM",
    prize: "27,937.37 USDT",
    isWon: false,
    outcome: "Draw in 2 days",
  },
  {
    id: "387",
    tickets: [
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
    ],
    createdAt: "17-08-2024 08:26 PM",
    prize: "27,937.37 USDT",
    isWon: false,
  },
  {
    id: "387",
    tickets: [
      {
        id: "#68329",
        isWon: true,
      },
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
    ],
    createdAt: "17-08-2024 08:26 PM",
    prize: "27,937.37 USDT",
    isWon: true,
  },
  {
    id: "387",
    tickets: [
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
    ],
    createdAt: "17-08-2024 08:26 PM",
    prize: "27,937.37 USDT",
    isWon: false,
  },
  {
    id: "387",
    tickets: [
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
    ],
    createdAt: "17-08-2024 08:26 PM",
    prize: "27,937.37 USDT",
    isWon: false,
  },
  {
    id: "387",
    tickets: [
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
      {
        id: "#68329",
        isWon: false,
      },
    ],
    createdAt: "17-08-2024 08:26 PM",
    prize: "27,937.37 USDT",
    isWon: false,
  },
];

export const LIFETIME_EARNINGS = [
  {
    place: "1st",
    name: "CoinMastery",
    prize: "20,378.00 USDT",
  },
  {
    place: "2nd",
    name: "BlockFiend",
    prize: "20,378.00 USDT",
  },
  {
    place: "3rd",
    name: "CryptoCraftr",
    prize: "20,378.00 USDT",
  },
  {
    place: "4th",
    name: "LedgerWhale",
    prize: "20,378.00 USDT",
  },
  {
    place: "5th",
    name: "TokenTrailblzr",
    prize: "20,378.00 USDT",
  },
];

export const LANGUAGES = [
  {
    name: "English",
    code: "en",
  },
  {
    name: "Chinese",
    code: "zh",
  },
  {
    name: "French",
    code: "fr",
  },
  {
    name: "German",
    code: "de",
  },
  {
    name: "Italian",
    code: "it",
  },
  {
    name: "Japanese",
    code: "ja",
  },
  {
    name: "Korean",
    code: "ko",
  },
  {
    name: "Portuguese",
    code: "pt",
  },
  {
    name: "Russian",
    code: "ru",
  },
  {
    name: "Spanish",
    code: "es",
  },
];

export const BUY_ORDERS = [
  {
    user: {
      name: "John Doe",
      orders: 136,
      completion: "100.00",
    },
    price: "639.930",
    available: "23,020.78 USDT",
    limit: "30.99 USDT - 400.00 USDT",
    chain: "Polygon",
    method: "Bank Transfer",
  },
  {
    user: {
      name: "John Doe",
      orders: 136,
      completion: "100.00",
    },
    price: "639.930",
    available: "23,020.78 USDT",
    limit: "30.99 USDT - 400.00 USDT",
    chain: "Polygon",
    method: "Bank Transfer",
  },
  {
    user: {
      name: "John Doe",
      orders: 136,
      completion: "100.00",
    },
    price: "639.930",
    available: "23,020.78 USDT",
    limit: "30.99 USDT - 400.00 USDT",
    chain: "Polygon",
    method: "Bank Transfer",
  },
  {
    user: {
      name: "John Doe",
      orders: 136,
      completion: "100.00",
    },
    price: "639.930",
    available: "23,020.78 USDT",
    limit: "30.99 USDT - 400.00 USDT",
    chain: "Polygon",
    method: "Bank Transfer",
  },
  {
    user: {
      name: "John Doe",
      orders: 136,
      completion: "100.00",
    },
    price: "639.930",
    available: "23,020.78 USDT",
    limit: "30.99 USDT - 400.00 USDT",
    chain: "Polygon",
    method: "Bank Transfer",
  },
];

export const P2P_BALANCES = [
  {
    icon: BitcoinIco,
    symbol: "BTC",
    name: "Bitcoin",
    usdValue: "0 USD",
    percentage: "-28%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
    chain: "BTC",
    freezeAmount: "19.03 USDT",
  },
  {
    icon: EthIco,
    symbol: "ETH",
    name: "Ethereum",
    usdValue: "0 USD",
    percentage: "12%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
    chain: "ETH",
    freezeAmount: "19.03 USDT",
  },
  {
    icon: BnbIco,
    symbol: "BNB",
    name: "Binance",
    usdValue: "0 USD",
    percentage: "-12%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
    chain: "BNB",
    freezeAmount: "19.03 USDT",
  },
  {
    icon: SolIco,
    symbol: "SOL",
    name: "Solana",
    usdValue: "0 USD",
    percentage: "8%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
    chain: "SOL",
    freezeAmount: "19.03 USDT",
  },
  {
    icon: UsdtIco,
    symbol: "USDT",
    name: "Tether",
    usdValue: "0 USD",
    percentage: "32%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
    chain: "USDT",
    freezeAmount: "19.03 USDT",
  },
  {
    icon: XrpIco,
    symbol: "XRP",
    name: "Ripple",
    usdValue: "0 USD",
    percentage: "-6%",
    amount: 0,
    amountUsd: 0,
    availableBalance: 0,
    chain: "XRP",
    freezeAmount: "19.03 USDT",
  },
];

export const MY_ORDERS = [
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    order: {
      type: "Buy",
      id: "0x003t4uieorp829300",
    },
    amount: "29.00 USDT",
    price: "$1.20 / USDT",
    qty: "435.00 USDT",
    time: "Aug 28, 2024 11:20",
    status: "In Process",
  },
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    order: {
      type: "Sell",
      id: "0x003t4uieorp829300",
    },
    amount: "29.00 USDT",
    price: "$1.20 / USDT",
    qty: "435.00 USDT",
    time: "Aug 28, 2024 11:20",
    status: "In Process",
  },
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    order: {
      type: "Buy",
      id: "0x003t4uieorp829300",
    },
    amount: "29.00 USDT",
    price: "$1.20 / USDT",
    qty: "435.00 USDT",
    time: "Aug 28, 2024 11:20",
    status: "In Process",
  },
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    order: {
      type: "Sell",
      id: "0x003t4uieorp829300",
    },
    amount: "29.00 USDT",
    price: "$1.20 / USDT",
    qty: "435.00 USDT",
    time: "Aug 28, 2024 11:20",
    status: "Cancelled",
  },
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    order: {
      type: "Buy",
      id: "0x003t4uieorp829300",
    },
    amount: "29.00 USDT",
    price: "$1.20 / USDT",
    qty: "435.00 USDT",
    time: "Aug 28, 2024 11:20",
    status: "Completed",
  },
];

export const ORDER_MESSAGES = [
  {
    user: "John Doe",
    message:
      "Hello, How are you? 3rd party nit acceptable please make payment from your own account",
    time: "Thursday 1 May, 2023",
    isMe: false,
    isRead: false,
  },
  {
    user: "Linda",
    message: "Ok sir, I am doing that.",
    time: "28 April, 2023",
    isMe: true,
    isRead: true,
  },
  {
    user: "John Doe",
    message: "Please share the screenshot.",
    time: "Thursday 1 May, 2023",
    isMe: false,
    isRead: false,
  },
  {
    user: "Linda",
    attachment: OrderSS,
    time: "28 April, 2023",
    isMe: true,
    isRead: true,
  },
];

export const MY_LISTINGS = [
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    amount: "435.00 ETH",
    available: "435.00 ETH",
    btc: {
      value: "200937.00 BTC",
      usd: "0.00",
    },
  },
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    amount: "435.00 ETH",
    available: "435.00 ETH",
    btc: {
      value: "200937.00 BTC",
      usd: "0.00",
    },
  },
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    amount: "435.00 ETH",
    available: "435.00 ETH",
    btc: {
      value: "200937.00 BTC",
      usd: "0.00",
    },
  },
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    amount: "435.00 ETH",
    available: "435.00 ETH",
    btc: {
      value: "200937.00 BTC",
      usd: "0.00",
    },
  },
  {
    coin: {
      name: "Ethereum",
      symbol: "ETH",
      icon: EthIco,
    },
    amount: "435.00 ETH",
    available: "435.00 ETH",
    btc: {
      value: "200937.00 BTC",
      usd: "0.00",
    },
  },
];
