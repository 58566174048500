import { Flex } from "@chakra-ui/react";
import React from "react";
import TradingEstimateBalanceCard from "../../../components/cards/TradingEstimateBalanceCard";
import CustomText from "../../../components/text/CustomText";
import TableSearchInput from "../../../components/form/TableSearchInput";
import P2PBalanceTable from "./P2PBalanceTable";

function P2PBalance() {
  return (
    <Flex flexDir="column" gap={{ base: "16px", lg: "40px" }}>
      <TradingEstimateBalanceCard p2p={true} />
      <Flex align="center" justify="space-between" gap="20px">
        <CustomText
          text="Trading Balance"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          lh={{ lg: "34px" }}
          family="Titillium Web"
          color="extsy.baseWhite"
          wrap="nowrap"
        />
        <TableSearchInput
          placeholder="Search"
          minW={{ base: "120px", lg: "250px" }}
          maxW={{ base: "140px", sm: "250px" }}
        />
      </Flex>
      <P2PBalanceTable />
    </Flex>
  );
}

export default P2PBalance;
