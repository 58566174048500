import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import WalletIcon from "../../assets/images/connect-wallet-ico.svg";
import PrimeIco from "../../assets/images/prime-ico.svg";
import NotificationIco from "../../assets/images/notification-ico.svg";
import LanguageIco from "../../assets/images/language-ico.svg";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";
import { IconShield, IconX } from "@tabler/icons-react";
import CustomModal from "../modal/customModal";
import Languages from "./Languages";
import HamburgerIco from "../../assets/images/hamburger-ico.svg";
import IconMenuClose from "../../assets/images/icon-menu-close.svg";
import { DASHBOARD_MENU, DASHBOARD_OTHER_MENU } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import GradientText from "../text/GradientText";
import LogoutModal from "../modal/LogoutModal";

function Navbar() {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    isOpen: isDisconnectOpen,
    onOpen: onDisconnectOpen,
    onClose: onDisconnectClose,
  } = useDisclosure();
  const {
    isOpen: isLogoutOpen,
    onOpen: onLogoutOpen,
    onClose: onLogoutClose,
  } = useDisclosure();
  const {
    isOpen: isMobileMenuOpen,
    onOpen: onMobileMenuOpen,
    onClose: onMobileMenuClose,
  } = useDisclosure();

  const [isLessThan992] = useMediaQuery("(max-width: 992px)");

  const [isNotificaitonOpen, setIsNotificationOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(
    localStorage.getItem("isConnected") || false
  );

  useEffect(() => {
    if (!isLessThan992) {
      onMobileMenuClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLessThan992]);

  return (
    <>
      <Flex gap="24px" w="100%" flexDir="column">
        {/* Mobile View */}
        <Flex
          justify="space-between"
          align="center"
          w="100%"
          display={{ base: "flex", lg: "none" }}
        >
          <Flex gap="8px" align="center">
            <UserProfile />
            <UserConnectWallet
              isConnected={isConnected}
              setIsConnected={setIsConnected}
              onDisconnectOpen={onDisconnectOpen}
            />
          </Flex>
          <Flex gap="8px" align="center">
            <UserNotifications
              isNotificaitonOpen={isNotificaitonOpen}
              setIsNotificationOpen={setIsNotificationOpen}
              setIsLangOpen={setIsLangOpen}
            />
            <ActionButton onClick={onMobileMenuOpen} img={HamburgerIco} />
          </Flex>
        </Flex>
        {/* For Desktop View */}
        <Flex
          justify={{ base: "flex-end", xl: "space-between" }}
          align="center"
          gap="24px"
          w="100%"
          display={{ base: "none", lg: "flex" }}
        >
          <Box
            display={{
              base: "none",
              xl: "block",
            }}
          >
            <CustomText
              text={t("welcomeText")}
              family="Titillium Web"
              size="28px"
              weight="700"
              color="extsy.neutral50"
              lh="36px"
            />
          </Box>
          <Flex gap="16px" align="center">
            <UserConnectWallet
              isConnected={isConnected}
              setIsConnected={setIsConnected}
              onDisconnectOpen={onDisconnectOpen}
            />
            <Flex
              cursor="pointer"
              bg="rgba(250, 250, 250, 0.10)"
              align="center"
              gap="8px"
              px="16px"
              h="32px"
              borderRadius="999px"
            >
              <Image src={PrimeIco} alt="prime" />
              <CustomText
                text={t("prime")}
                color="extsy.baseWhite"
                size="14px"
                cursor="pointer"
              />
            </Flex>
            <UserProfile />
            <Flex align="center" gap="8px">
              <UserLanguages
                isLangOpen={isLangOpen}
                setIsLangOpen={setIsLangOpen}
                setIsNotificationOpen={setIsNotificationOpen}
              />
              <UserNotifications
                isNotificaitonOpen={isNotificaitonOpen}
                setIsNotificationOpen={setIsNotificationOpen}
                setIsLangOpen={setIsLangOpen}
              />
            </Flex>
          </Flex>
        </Flex>
        <CustomModal
          isOpen={isDisconnectOpen}
          onClose={() => {
            onDisconnectClose();
          }}
          onSubmit={() => {
            onDisconnectClose();
            setIsConnected(false);
            localStorage.removeItem("isConnected");
          }}
          headerText={t("disconnectWallet")}
          btnText={t("yesDisconnect")}
          closeBtntext={t("cancel")}
          width={"600px"}
        >
          <Flex p="32px 24px" flexDir="column" gap="32px" alignItems="center">
            <CustomText
              text={t("areYouSureDisconnectWallet")}
              align="center"
              color="extsy.neutral300"
            />
          </Flex>
        </CustomModal>
      </Flex>
      <Drawer
        isOpen={isMobileMenuOpen}
        placement="left"
        size="full"
        onClose={() => {}}
      >
        <DrawerOverlay />
        <DrawerContent bg="extsy.bg">
          <DrawerBody p="24px" overflowY="auto">
            <Flex flexDir="column" gap="16px">
              <Flex justify="space-between" gap="16px" align="center" pb="8px">
                <UserProfile isMenu={true} />
                <Flex gap="8px" align="center">
                  <UserLanguages
                    isLangOpen={isLangOpen}
                    setIsLangOpen={setIsLangOpen}
                    setIsNotificationOpen={setIsNotificationOpen}
                  />
                  <ActionButton
                    onClick={onMobileMenuClose}
                    img={IconMenuClose}
                  />
                </Flex>
              </Flex>
              <Divider
                color="extsy.neutral1000"
                borderColor="extsy.neutral1000"
                opacity="1"
              />
              {DASHBOARD_MENU.map((menu, index) => (
                <Flex
                  key={index}
                  gap="8px"
                  align="center"
                  cursor="pointer"
                  onClick={() => {
                    navigate(menu.path);
                    onMobileMenuClose();
                  }}
                >
                  <menu.icon
                    size="24px"
                    color={menu?.path === pathname ? "#F0C2DB" : "#A5A1A1"}
                  />
                  {menu?.path === pathname ? (
                    <GradientText
                      text={t(menu.key)}
                      bg="extsy.g1"
                      cursor="pointer"
                    />
                  ) : (
                    <CustomText
                      text={t(menu.key)}
                      color="extsy.neutral300"
                      cursor="pointer"
                    />
                  )}
                </Flex>
              ))}
              <Divider
                color="extsy.neutral1000"
                borderColor="extsy.neutral1000"
                opacity="1"
              />
              {DASHBOARD_OTHER_MENU.map((menu, index) => (
                <Flex
                  key={index}
                  gap="8px"
                  align="center"
                  cursor="pointer"
                  onClick={() => {
                    if (menu?.name === "Logout") {
                      onLogoutOpen();
                    } else if (menu?.path) {
                      navigate(menu.path);
                      onMobileMenuClose();
                    }
                  }}
                >
                  <menu.icon
                    size="24px"
                    color={
                      menu?.name === "Logout"
                        ? "#FF727F"
                        : menu?.path === pathname
                        ? "#F0C2DB"
                        : "#A5A1A1"
                    }
                  />
                  {menu?.path === pathname ? (
                    <GradientText
                      text={t(menu.key)}
                      bg="extsy.g1"
                      cursor="pointer"
                    />
                  ) : (
                    <CustomText
                      text={t(menu.key)}
                      color="extsy.neutral300"
                      cursor="pointer"
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Logout Modal */}
      <LogoutModal isOpen={isLogoutOpen} onClose={onLogoutClose} />
    </>
  );
}

const UserProfile = ({ isMenu }: any) => {
  return (
    <Flex align="center" gap="8px">
      <Avatar src="" name="SA" boxSize="48px" bg="extsy.g1" color="extsy.bg" />
      <Flex
        flexDir="column"
        gap="6px"
        display={{
          base: isMenu ? "flex" : "none",
          lg: "flex",
        }}
      >
        <CustomText
          text="Serra Alexandra"
          color="extsy.baseWhite"
          weight="600"
        />
        <Flex align="center" gap="8px">
          <IconShield size="16px" color="#fff" />
          <CustomText text="Verify Account" size="10px" weight="500" />
        </Flex>
      </Flex>
    </Flex>
  );
};

const UserConnectWallet = ({
  isConnected,
  onDisconnectOpen,
  setIsConnected,
}: any) => {
  const { t } = useTranslation();
  return (
    <Button
      bg="extsy.g1"
      borderRadius="999px"
      height={{ base: "34px", lg: "48px" }}
      display="flex"
      alignItems="center"
      ps="4px"
      pe="16px"
      gap="10px"
      _hover={{ bg: "extsy.g1" }}
      onClick={() => {
        if (isConnected) {
          onDisconnectOpen();
        } else {
          localStorage.setItem("isConnected", "true");
          setIsConnected(true);
        }
      }}
    >
      <Image
        src={WalletIcon}
        alt="wallet"
        boxSize={{
          base: "25px",
          lg: "40px",
        }}
      />

      <CustomText
        text={isConnected ? "0x0000...00000000" : t("connectWallet")}
        size={{ base: "12px", lg: "14px" }}
        weight={{ base: "400", lg: "600" }}
        family="Titillium Web"
        color="extsy.bg"
        cursor="pointer"
        ls="0.28px"
      />
      {isConnected && <IconX size={18} color="#000" />}
    </Button>
  );
};

const UserNotifications = ({
  isNotificaitonOpen,
  setIsNotificationOpen,
  setIsLangOpen,
}: any) => {
  return (
    <ActionButton
      onClick={() => {
        setIsNotificationOpen(!isNotificaitonOpen);
        setIsLangOpen(false);
      }}
      img={NotificationIco}
      child={isNotificaitonOpen && <Notifications />}
    />
  );
};

const UserLanguages = ({
  isLangOpen,
  setIsLangOpen,
  setIsNotificationOpen,
}: any) => {
  return (
    <ActionButton
      onClick={() => {
        setIsNotificationOpen(false);
        setIsLangOpen(!isLangOpen);
      }}
      img={LanguageIco}
      child={isLangOpen && <Languages />}
    />
  );
};

const ActionButton = ({ onClick, img, child }: any) => {
  return (
    <Flex
      flexDir="column"
      justify="center"
      align="center"
      height={{ base: "34px", lg: "48px" }}
      width={{ base: "34px", lg: "48px" }}
      bg="extsy.neutral1000"
      borderRadius="99px"
      border="1px solid"
      borderColor="extsy.neutral900"
      cursor="pointer"
      pos="relative"
      onClick={onClick}
    >
      <Image src={img} alt="menu" boxSize={{ base: "18px", lg: "24px" }} />
      {child}
    </Flex>
  );
};

export default Navbar;
