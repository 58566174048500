import { Box, Flex, Image, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import CardBg from "../../assets/images/card-bg.svg";
import TicketVector from "../../assets/images/ticket-vector.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import {
  IconAlertHexagon,
  IconCheck,
  IconChevronDown,
  IconTicket,
} from "@tabler/icons-react";
import GradientText from "../../components/text/GradientText";
import CustomModal from "../../components/modal/customModal";
import USDTIco from "../../assets/images/usdt-ico.svg";
import ManCongrats from "../../assets/images/man-congrats.png";
import USDTTrophy from "../../assets/images/usdt-trophy.svg";

function Tickets() {
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
  const [isPurchaseRaffleOpen, setIsPurchaseRaffleOpen] = useState(false);
  const [isTicketPurchased, setIsTicketPurchased] = useState(false);
  const [isWon, setIsWon] = useState(false);
  const [isCollectOpen, setIsCollectOpen] = useState(false);
  const [isTickerDropOpen, setIsTicketDropOpen] = useState(false);
  const [activeTicker, setActiveTicket] = useState(1);
  const findActiveTicket = () => {
    return TICKET_ITEMS.find((item) => item.id === activeTicker);
  };

  const TICKET_ITEMS = [
    {
      id: 1,
      number: 1,
      price: "5.00",
    },
    {
      id: 2,
      number: 3,
      price: "15.00",
    },
    {
      id: 3,
      number: 5,
      price: "20.00",
    },
  ];

  useEffect(() => {
    if (isPurchaseRaffleOpen) {
      setTimeout(() => {
        setIsPurchaseRaffleOpen(false);
        setIsTicketPurchased(true);
      }, 3000);
    }
  }, [isPurchaseRaffleOpen]);

  useEffect(() => {
    if (isTicketPurchased) {
      setTimeout(() => {
        setIsCollectOpen(true);
        setIsWon(true);
      }, 5000);
    }
  }, [isTicketPurchased]);

  return (
    <>
      <Flex
        maxW={{ "1xl": "423px" }}
        w="100%"
        minH="524px"
        flexDir="column"
        justify="space-between"
        p={{ base: "16px", lg: "32px" }}
        borderRadius={{ base: "16px", lg: "24px" }}
        border="1px solid"
        borderColor="extsy.neutral1000"
        bg="extsy.neutral1000"
        gap={{ base: "16px", lg: "24px" }}
        pos="relative"
        overflow="hidden"
      >
        <Box
          width="472px"
          height="88px"
          pos="absolute"
          top="0"
          right="0"
          mt="-45px"
          mr="-25px"
          bg="extsy.primary500"
          filter="blur(82px)"
          display={isWon ? "block" : "none"}
        />
        <Flex flexDir="column" zIndex="100">
          <CustomText
            text={isWon ? "Congratulations" : "Tickets"}
            size={{ base: "18px", lg: "24px" }}
            lh={{ lg: "34px" }}
            weight={{ base: "600", lg: "700" }}
            family="Titillium Web"
            color="extsy.white"
            align={isWon ? "center" : "start"}
          />
          <Flex
            height="262px"
            width="100%"
            bgImage={`url(${CardBg})`}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPos="center"
            overflow="hidden"
            borderRadius="0px 0px 200px 200px"
            align={isWon ? "flex-end" : ""}
            justify="center"
            mt={isWon ? "44px" : "0px"}
          >
            <Image src={isWon ? ManCongrats : TicketVector} alt="ticket" />
          </Flex>
        </Flex>
        {isWon ? (
          <Flex flexDir="column" gap="24px" align="center">
            <CustomText
              text="Ticket #63829 has won the August raffle prize of"
              align="center"
            />
            <GradientText
              text="$ 25,000.00"
              bg="extsy.g1"
              size="32px"
              lh="40px"
              weight="700"
            />
          </Flex>
        ) : isTicketPurchased ? (
          <Flex
            bg="extsy.g1"
            height="65px"
            align="center"
            justify="space-between"
            px="16px"
            borderRadius="16px"
            cursor="pointer"
          >
            <CustomText
              text="Tickets Purchased this Month"
              size="14px"
              color="extsy.bg"
              cursor
            />
            <Flex align="center" gap="8px">
              <CustomText text="1" size="20px" color="extsy.bg" weight="600" />
              <IconTicket color="#E35CAD" size="22px" />
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex flexDir="column" gap="12px">
              <CustomText size="14px" text="Select Tickets" />
              <Box pos="relative">
                <Flex
                  cursor="pointer"
                  borderRadius="16px"
                  bg="extsy.neutral900"
                  py="9.5px"
                  px="19.5px"
                  align="center"
                  justify="space-between"
                  height="56px"
                  onClick={() => setIsTicketDropOpen(!isTickerDropOpen)}
                >
                  <CustomText
                    text={`${findActiveTicket()?.number} Ticket for ${
                      findActiveTicket()?.price
                    } USDT`}
                    size="14px"
                    cursor
                  />
                  <IconChevronDown size="16px" color="#fafafa" />
                </Flex>
                <Flex
                  flexDir="column"
                  gap="16px"
                  p="16px"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  bg="extsy.neutral900"
                  pos="absolute"
                  top="0"
                  mt="60px"
                  width="100%"
                  zIndex="1000"
                  display={isTickerDropOpen ? "flex" : "none"}
                >
                  {TICKET_ITEMS.map((item, index) => (
                    <Flex
                      key={index}
                      align="center"
                      justify="space-between"
                      py="8px"
                      cursor="pointer"
                      onClick={() => {
                        setIsTicketDropOpen(false);
                        setActiveTicket(item.id);
                      }}
                    >
                      <CustomText
                        text={`${item.number} Ticket for ${item.price} USDT`}
                        size="14px"
                        color={
                          item?.id === activeTicker
                            ? "extsy.baseWhite"
                            : "extsy.neutral300"
                        }
                        cursor
                      />
                      {item.id === activeTicker && (
                        <IconCheck size="16px" color="#fafafa" />
                      )}
                    </Flex>
                  ))}
                </Flex>
              </Box>
              <CustomText
                size="12px"
                text="This is a one-time purchase, so you won’t be able to buy more tickets for this month. Make your choice carefully."
                color="extsy.neutral500"
              />
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              py="16px"
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor="extsy.neutral900"
            >
              <CustomText
                text="Total Amount"
                size="14px"
                color="extsy.baseWhite"
              />
              <GradientText
                text="20.00 USDT"
                size="18px"
                weight="600"
                bg="extsy.g1"
              />
            </Flex>
            <PrimaryButton
              title="Purchase Raffle Ticket"
              onClick={() => setIsPurchaseOpen(true)}
            />
          </>
        )}
      </Flex>
      <CustomModal
        isOpen={isPurchaseOpen}
        onClose={() => setIsPurchaseOpen(false)}
        onSubmit={() => {
          setIsPurchaseOpen(false);
          setIsPurchaseRaffleOpen(true);
        }}
        headerText={"Purchase Raffle Ticket"}
        btnText={"Connect Wallet"}
        closeBtntext={"Close"}
        width={"600px"}
        bgtopBlur="transparent"
      >
        <Flex p={{ base: "16px", lg: "32px 24px" }} flexDir="column" gap="32px">
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <Flex
              gap="12px"
              align="center"
              px="19.5px"
              py="9.5px"
              h="56px"
              bg="extsy.neutral900"
              borderRadius="16px"
            >
              <Image src={USDTIco} alt="usdt" h="28px" w="28px" />
              <CustomText
                text="USDT TRC20"
                color="extsy.baseWhite"
                weight="600"
              />
            </Flex>
            <CustomText
              text={
                <>
                  <span>Don't have USDT? </span>
                  <span
                    style={{ color: "#fafafa", textDecoration: "underline" }}
                  >
                    Buy now
                  </span>
                </>
              }
              align="end"
              mt="12px"
            />
          </Flex>
          <Flex
            align="center"
            justify="space-between"
            pt="24px"
            borderTop="1px solid"
            borderColor="extsy.neutral900"
          >
            <CustomText text="Total Amount" color="extsy.baseWhite" />
            <CustomText
              text="20.00 USDT"
              color="extsy.baseWhite"
              weight="500"
              size={{ base: "20px", lg: "32px" }}
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isPurchaseRaffleOpen}
        onClose={() => setIsPurchaseRaffleOpen(false)}
        onSubmit={() => {
          setIsPurchaseRaffleOpen(false);
        }}
        headerText={"Purchase Raffle"}
        noFooter
        width={"600px"}
        bgtopBlur="transparent"
      >
        <Flex
          p={{ base: "16px", lg: "24px" }}
          flexDir="column"
          gap={{ base: "40px", lg: "48px" }}
        >
          <Flex flexDir="column" gap={{ base: "16px", lg: "40px" }}>
            <Flex align="center" justify="space-between" gap="12px">
              <CustomText
                text="Raffle Plan"
                size={{ base: "16px", lg: "18px" }}
              />
              <CustomText
                text="3 Tickets for 15.00 USDT"
                size={{ base: "16px", lg: "24px" }}
                weight="600"
                color="extsy.baseWhite"
              />
            </Flex>
            <Flex align="center" justify="space-between">
              <CustomText
                text="Total Amount"
                size={{ base: "16px", lg: "18px" }}
              />
              <CustomText
                text="20.00 USDT"
                color="extsy.baseWhite"
                weight="600"
                size={{ base: "16px", lg: "24px" }}
              />
            </Flex>
          </Flex>
          <Flex gap="24px" align="center" justify="center">
            <Spinner size="md" color="#FEF9CC" />
            <CustomText
              text="Loadig..."
              size="18px"
              lh="22px"
              family="Titillium Web"
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isCollectOpen}
        onClose={() => setIsCollectOpen(false)}
        onSubmit={() => {
          setIsCollectOpen(false);
        }}
        headerText={"Collect Your Prize"}
        btnText={"Connect Wallet"}
        closeBtntext={"Close"}
        width={"700px"}
        bgtopBlur="#15B097"
      >
        <Flex p={{ base: "16px", lg: "24px" }} flexDir="column" gap="24px">
          <Flex flexDir="column" gap="16px" py="12px" align="center">
            <Image src={USDTTrophy} alt="trophy" w="141px" h="130px" />
            <GradientText
              text="25,000.00 USDT"
              size="32px"
              lh="40px"
              bg="extsy.g1"
              weight="700"
            />
            <CustomText
              text="Enter the wallet address below and collect your prize."
              align="center"
            />
          </Flex>
          <Flex
            gap="10px"
            p="16px"
            borderRadius="8px"
            bg="extsy.bg"
            align="center"
            h="66px"
          >
            <Box>
              <IconAlertHexagon size="20px" color="#E35CAD" />
            </Box>
            <CustomText
              text="Please make sure the connected wallet is for USDT TRC20, otherwise your funds may be lost."
              size="14px"
            />
          </Flex>
        </Flex>
      </CustomModal>
    </>
  );
}

export default Tickets;
