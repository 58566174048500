import { Text, Flex, useMediaQuery, Img } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import CustomVerificationInput from "../../components/form/customVerificationInput";
import Countdown from "react-countdown";
import CustomModal from "../../components/modal/customModal";
import CheckMarkImg from "../../assets/images/correctIcon.svg";
import { useTranslation } from "react-i18next";

function SecurityVerification() {
  const { t } = useTranslation();
  const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const [open, setOpen] = useState(false);

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <Flex flexDir="column" gap="16px">
          <CustomText
            text={t("timeRemaining")}
            align="center"
            color="extsy.baseWhite"
          />
          <Flex justify="center" align="center" gap="5px">
            <Flex
              align="center"
              justify="center"
              bg="extsy.neutral1000"
              borderRadius="8px"
              px="10px"
              h="40px"
              color="extsy.primary"
              fontFamily={"Metropolis"}
            >
              {"0" + minutes}
            </Flex>
            <Text
              align="center"
              color="extsy.primary"
              fontSize="26px"
              fontWeight="500"
            >
              :
            </Text>
            <Flex
              align="center"
              justify="center"
              bg="extsy.neutral1000"
              borderRadius="8px"
              px="10px"
              h="40px"
              color="extsy.primary"
              fontFamily={"Metropolis"}
            >
              {seconds < 10 ? "0" + seconds : seconds}
            </Flex>
          </Flex>
        </Flex>
      );
    }
  };

  const Completionist = () => (
    <CustomText text={t("resendOTP")} align="center" color="extsy.baseWhite" />
  );

  return (
    <>
      <Flex
        px={{ base: "16px", md: "40px" }}
        py={isLessThan768 ? "24px" : "56px"}
        mx="auto"
        bg="rgba(255, 255, 255, 0.03)"
        borderRadius={{ base: "16px", md: "24px" }}
        maxW="480px"
        w="100%"
        flexDir="column"
        justify="space-between"
        gap={isLessThan768 ? "24px" : "0px"}
        minH={isLessThan768 ? "524px" : "742px"}
      >
        <Flex align="center" flexDir="column" gap="32px">
          <CustomText
            text={t("enterVerificationCode")}
            family="Titillium Web"
            size="24px"
            lh="28px"
            weight="700"
            align="center"
            color="extsy.baseWhite"
          />
          <CustomText
            text={
              <Text color="extsy.neutral300">
                {t("verificationCodeDescription")}{" "}
                <Text as="span" color="extsy.baseWhite">
                  user@email.com
                </Text>
              </Text>
            }
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
        <Flex flexDir="column" align="center" justify="center" gap="40px">
          <CustomVerificationInput placeholder={0} />
          <Countdown
            date={Date.now() + 70000}
            renderer={renderer}
            key={Date.now()}
          />
        </Flex>
        <Flex flexDir="column" gap="24px" align="center">
          <PrimaryButton title={t("verify")} onClick={handleModalOpen} />
          {/* <Flex align="center" gap="4px">
            <CustomText
              text={t("rememberPassword?")}
              color="extsy.neutral300"
              lh="19px"
            />
            <GradientText
              text={t("signIn")}
              weight="600"
              bg="extsy.g1"
              lh="22px"
              cursor="pointer"
              onClick={() => {
                navigate("/auth/login");
              }}
            />
          </Flex> */}
        </Flex>
      </Flex>

      <CustomModal
        isOpen={open}
        onClose={handleModalClose}
        onSubmit={handleModalClose}
        noFooter={true}
        noHeader={true}
        padding="56px"
      >
        <Flex flexDir="column" align="center" gap={"32px"} minH="100%">
          <Img src={CheckMarkImg} alt="checkicon" minH="80px" minW="79.99px" />
          <CustomText
            text={t("congratulations")} // Use translation key
            color="extsy.baseWhite"
            lh="19.2px"
            size="24px"
            family="Titillium Web"
            pb="5px"
          />
          <CustomText
            text={t("accountRegisteredSuccess")} // Use translation key
            color="extsy.neutral300"
            size="13.2px"
          />
          <PrimaryButton title={t("continue")} onClick={handleModalClose} />{" "}
          {/* Use translation key */}
        </Flex>
      </CustomModal>
    </>
  );
}

export default SecurityVerification;
