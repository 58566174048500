import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import VCardInput from "../../../components/form/vCardInput";
import { IconMinus, IconX } from "@tabler/icons-react";

function Step2({ sell }: any) {
  return (
    <Flex flexDir="column" gap={{ base: "16px", md: "32px" }}>
      <Flex
        align="center"
        justify="space-between"
        gap={{ base: "16px", md: "86px" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Total Amount" size="14px" />
          <VCardInput
            placeholder="0.00"
            bg="extsy.neutral900"
            maxW="100%"
            radius="12px"
            color="extsy.neutral300"
            rightIco={<CustomText text="USDT" size="14px" />}
          />
          <CustomText text="≈ $64.10" size="14px" />
        </Flex>
        {sell && (
          <Flex flexDir="column" gap="8px" w="100%">
            <CustomText text="Available" size="14px" />
            <Flex
              align={{ md: "center" }}
              justify="space-between"
              gap={{ base: "16px", md: "0px" }}
              flexDir={{
                base: "column",
                md: "row",
              }}
            >
              <CustomText
                text="20.276372890 USDT"
                size={{ base: "22px", md: "32px" }}
                weight="700"
                color="extsy.secondary"
              />
              <Flex
                h="56px"
                align="center"
                justify="center"
                px="16px"
                bg="extsy.neutral700"
                border="1px dashed"
                borderColor="extsy.neutral500"
                borderRadius="12px"
                maxW={{ md: "152px" }}
                w="100%"
              >
                <CustomText
                  text="Deposit"
                  size="14px"
                  color="extsy.baseWhite"
                />
              </Flex>
            </Flex>
            <CustomText text="≈ $64.10" size="14px" />
          </Flex>
        )}
      </Flex>
      <Flex gap={{ base: "8px", md: "32px" }} align="center">
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Order Limit" size="14px" />
          <VCardInput
            placeholder="0.00"
            bg="extsy.neutral900"
            maxW="100%"
            radius="12px"
            color="extsy.neutral300"
            rightIco={<CustomText text="$" size="14px" />}
          />
          <CustomText text="≈ 3.59 USDT" size="14px" />
        </Flex>
        <Box
          display={{
            base: "none",
            md: "block",
          }}
        >
          <IconMinus size={24} color="#FFFFFF" />
        </Box>
        <Flex flexDir="column" gap="8px" w="100%" mt="22px">
          <VCardInput
            placeholder="0.00"
            bg="extsy.neutral900"
            maxW="100%"
            radius="12px"
            color="extsy.neutral300"
            rightIco={<CustomText text="$" size="14px" />}
          />
          <CustomText text="≈ 2690.50 USDT" size="14px" />
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", md: "85px" }}
        align="center"
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Payment Method" size="14px" />
          <Flex
            gap="16px"
            flexDir={{
              base: "column",
              md: "row",
            }}
          >
            <Flex
              maxW="207px"
              w="100%"
              gap="16px"
              align="center"
              justify="space-between"
              h="56px"
              px="16px"
              borderRadius="12px"
              bg="rgba(227, 92, 173, 0.10)"
            >
              <CustomText
                text="Bank Payment"
                size="14px"
                color="extsy.primary500"
              />
              <IconX size={21} color="#E35CAD" />
            </Flex>
            <Flex
              px="16px"
              w="100%"
              h="56px"
              border="1px dashed"
              borderColor="extsy.neutral500"
              bg="extsy.neutral700"
              borderRadius="12px"
              cursor="pointer"
              align="center"
              justify="center"
            >
              <CustomText
                text="Add Payment Method"
                size="14px"
                color="extsy.baseWhite"
                cursor
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Payment Time Limit" size="14px" />
          <VCardInput
            placeholder="15 Mins"
            bg="extsy.neutral900"
            maxW="100%"
            radius="12px"
            color="extsy.neutral300"
            rightIco={<CustomText text="$" size="14px" />}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Step2;
