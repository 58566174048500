import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import Countdown from "react-countdown";
import ColonIcon from "../../assets/images/colon-icon.svg";
import FlipBg from "../../assets/images/timer-flip-bg.svg";

function NextDraw() {
  return (
    <Flex
      p={{ base: "20px 23px", lg: "20px 32px 20px 32px" }}
      borderRadius={{ base: "11px", lg: "16px" }}
      bg="extsy.bg"
      flexDir="column"
      gap={{ base: "16px", lg: "24px" }}
      maxW={{ "1xl": "440px" }}
      width="100%"
      zIndex="100"
      overflow="hidden"
      pos="relative"
    >
      <CustomText
        text="Next Draw Coming In"
        size={{ base: "14px", lg: "20px" }}
        lh={{ lg: "30px" }}
        align="center"
        family="Titillium Web"
        weight="600"
        color="extsy.neutral50"
      />
      <Countdown
        date={Date.now() + 5 * 24 * 60 * 60 * 1000}
        key={Date.now() + 5 * 24 * 60 * 60 * 1000}
        renderer={flipTimer}
      />
      <Box
        height="193px"
        width="193px"
        bg="#15B097"
        filter="blur(82.5px)"
        borderRadius="193px"
        pos="absolute"
        top="0"
        right="0"
        mt="-132px"
        mr="-118px"
      />
    </Flex>
  );
}

const flipTimer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return (
      <CustomText text="Draw is live" size="20px" color="extsy.neutral50" />
    );
  } else {
    return (
      <Flex justify="center" align="center" gap="8px">
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height={{ base: "45px", md: "53px", lg: "72px" }}
              width={{ base: "51px", md: "61px", lg: "83px" }}
            >
              <CustomText
                text={days < 10 ? `0${days}` : days}
                size={{ base: "36px", md: "52px", lg: "70px" }}
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="DAYS"
              size={{ base: "8px", lg: "12px" }}
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height={{ base: "45px", md: "53px", lg: "72px" }}
              width={{ base: "51px", md: "61px", lg: "83px" }}
            >
              <CustomText
                text={hours < 10 ? `0${hours}` : hours}
                size={{ base: "36px", md: "52px", lg: "70px" }}
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="HOURS"
              size={{ base: "8px", lg: "12px" }}
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height={{ base: "45px", md: "53px", lg: "72px" }}
              width={{ base: "51px", md: "61px", lg: "83px" }}
            >
              <CustomText
                text={minutes < 10 ? `0${minutes}` : minutes}
                size={{ base: "36px", md: "52px", lg: "70px" }}
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="MINUTES"
              size={{ base: "8px", lg: "12px" }}
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex gap="8px" flexDir="column" align="center">
          <Flex
            bgImage={FlipBg}
            bgSize="cover"
            align="center"
            justify="center"
            height={{ base: "45px", md: "53px", lg: "72px" }}
            width={{ base: "51px", md: "61px", lg: "83px" }}
          >
            <CustomText
              text={seconds < 10 ? `0${seconds}` : seconds}
              size={{ base: "36px", md: "52px", lg: "70px" }}
              color="extsy.neutral50"
              family="Share Tech"
            />
          </Flex>
          <CustomText
            text="SECONDS"
            size={{ base: "8px", lg: "12px" }}
            weight="600"
            color="extsy.neutral50"
          />
        </Flex>
      </Flex>
    );
  }
};

export default NextDraw;
