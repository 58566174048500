import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import WalletIco from "../../assets/images/wallet-ico-gradient.svg";
import TradingIco from "../../assets/images/trading-ico-gradient.svg";
import P2PIco from "../../assets/images/p2p-ico-gradient.svg";

function AvailableBalanceCard() {
  const { t } = useTranslation();

  return (
    <Flex
      gap={{ base: "8px", lg: "24px" }}
      align="center"
      flexDir={{ base: "column", lg: "row" }}
    >
      <Flex
        minH={{ base: "72px", lg: "140px", "1xl": "220px" }}
        w="100%"
        gap={{ base: "16px", lg: "24px" }}
        pos="relative"
        flexDir={{ base: "row", lg: "column" }}
        overflow="hidden"
        align={{ base: "center", lg: "flex-start" }}
        justify={{ lg: "space-between" }}
        p={{ base: "16px", lg: "20px", "1xl": "40px" }}
        borderRadius={{ base: "16px", "1xl": "40px" }}
        bg="extsy.neutral1000"
      >
        <Box>
          <Flex
            align="center"
            justify="center"
            zIndex="100"
            bg="extsy.neutral900"
            borderRadius="8px"
            height="40px"
            width="40px"
          >
            <Image src={WalletIco} alt="wallet" height="24px" width="24px" />
          </Flex>
        </Box>
        <Flex
          flexDir={{ base: "row", lg: "column" }}
          align={{ base: "center", lg: "flex-start" }}
          justify={{ base: "space-between", lg: "flex-start" }}
          w="100%"
          gap="16px"
        >
          <CustomText text={"Wallet"} color="extsy.baseWhite" />
          <CustomText
            text={"$0.00"}
            color="extsy.secondary100"
            size={{ base: "24px", lg: "36px" }}
          />
        </Flex>
        <Box
          w="696px"
          h="245px"
          borderRadius="696px"
          opacity="0.6"
          bg="extsy.primary500"
          filter="blur(97px)"
          transform="rotate(-30deg)"
          pos="absolute"
          left="0"
          top="0"
          ml="-400px"
          mt="-240px"
        />
      </Flex>
      <Flex
        minH={{ base: "72px", lg: "140px", "1xl": "220px" }}
        w="100%"
        gap={{ base: "16px", lg: "24px" }}
        pos="relative"
        flexDir={{ base: "row", lg: "column" }}
        overflow="hidden"
        align={{ base: "center", lg: "flex-start" }}
        justify={{ lg: "space-between" }}
        p={{ base: "16px", lg: "20px", "1xl": "40px" }}
        borderRadius={{ base: "16px", "1xl": "40px" }}
        bg="extsy.neutral1000"
      >
        <Box>
          <Flex
            align="center"
            justify="center"
            zIndex="100"
            bg="extsy.neutral900"
            borderRadius="8px"
            height="40px"
            width="40px"
          >
            <Image src={TradingIco} alt="wallet" height="24px" width="24px" />
          </Flex>
        </Box>
        <Flex
          flexDir={{ base: "row", lg: "column" }}
          align={{ base: "center", lg: "flex-start" }}
          justify={{ base: "space-between", lg: "flex-start" }}
          w="100%"
          gap="16px"
        >
          <CustomText text={t("trading")} color="extsy.baseWhite" />
          <CustomText
            text={"$0.00"}
            color="extsy.secondary100"
            size={{ base: "24px", lg: "36px" }}
          />
        </Flex>
      </Flex>
      <Flex
        minH={{ base: "72px", lg: "140px", "1xl": "220px" }}
        w="100%"
        gap={{ base: "16px", lg: "24px" }}
        pos="relative"
        flexDir={{ base: "row", lg: "column" }}
        overflow="hidden"
        align={{ base: "center", lg: "flex-start" }}
        justify={{ lg: "space-between" }}
        p={{ base: "16px", lg: "20px", "1xl": "40px" }}
        borderRadius={{ base: "16px", "1xl": "40px" }}
        bg="extsy.neutral1000"
      >
        <Box>
          <Flex
            align="center"
            justify="center"
            zIndex="100"
            bg="extsy.neutral900"
            borderRadius="8px"
            height="40px"
            width="40px"
          >
            <Image src={P2PIco} alt="wallet" height="24px" width="24px" />
          </Flex>
        </Box>
        <Flex
          flexDir={{ base: "row", lg: "column" }}
          align={{ base: "center", lg: "flex-start" }}
          justify={{ base: "space-between", lg: "flex-start" }}
          w="100%"
          gap="16px"
        >
          <CustomText text={t("p2p")} color="extsy.baseWhite" />
          <CustomText
            text={"$0.00"}
            color="extsy.secondary100"
            size={{ base: "24px", lg: "36px" }}
          />
        </Flex>
        <Box
          w="696px"
          h="245px"
          borderRadius="696px"
          opacity="0.6"
          bg="#15B097"
          filter="blur(97px)"
          transform="rotate(-30deg)"
          pos="absolute"
          right="0"
          bottom="0"
          mr="-398px"
          mb="-240px"
        />
      </Flex>
    </Flex>
  );
}

export default AvailableBalanceCard;
