import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import NoCardIco from "../../assets/images/no-card-ico.svg";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../button/PrimaryButton";
import CustomModal from "../modal/customModal";
import CancelAlertIco from "../../assets/images/cancel-alert-ico.svg";
import CardBg from "../../assets/images/card-bg.svg";
import ExtsyLogo from "../../assets/images/logo-icon.svg";
import MasterCardIco from "../../assets/images/master-card-ico.svg";
import { IconAlertTriangle, IconEye, IconEyeOff } from "@tabler/icons-react";
import TopUpIco from "../../assets/images/topup-ico.svg";
import CustomVerificationInput from "../form/customVerificationInput";
import Countdown from "react-countdown";
import VirtualCardDummy from "../../assets/images/virtual-card-dummy.svg";
import CustomSelect from "../form/CustomSelect";
import USDTIco from "../../assets/images/usdt-ico.svg";
import VCardInput from "../form/vCardInput";

function OrderNowCard({ isVerified, setIsVerified }: any) {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isActiveOpen,
    onOpen: onActivateOpen,
    onClose: onActivateClose,
  } = useDisclosure();
  const {
    isOpen: isTopupOpen,
    onOpen: onTopupOpen,
    onClose: onTopupClose,
  } = useDisclosure();

  const [isActivated, setIsActivited] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  return (
    <>
      {isVerified ? (
        <Flex
          align="center"
          justify={{ base: "center", "1xl": "auto" }}
          gap="16px"
          flexDir="column"
          p="24px 0px 16px 0px"
          borderRadius="24px"
          bg="extsy.neutral1000"
          borderWidth="1px solid"
          borderColor="extsy.neutral1000"
          h="100%"
        >
          <Box
            mx="24px"
            height="254px"
            width="406px"
            bgImage={`url(${CardBg})`}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPos="center"
            overflow="hidden"
            borderRadius="0px 0px 200px 200px"
          >
            <Box
              bg="rgba(0, 0, 0, 0.40)"
              borderRadius="22px"
              backdropFilter="blur(65px)"
              width="220px"
              height="370px"
              mx="auto"
              p="18px"
            >
              <Flex align="center" justify="space-between">
                <Image
                  src={ExtsyLogo}
                  alt="extsy"
                  draggable={false}
                  width="41px"
                  height="29px"
                />
                <Image
                  src={MasterCardIco}
                  alt="master-card"
                  draggable={false}
                  width="41px"
                  height="29px"
                />
              </Flex>
              {isActivated ? (
                <Flex flexDir="column" gap="26px" mt="56px">
                  <Flex flexDir="column" gap="6.3px">
                    <CustomText
                      text="Serra Alexandra"
                      size="10.7px"
                      color="extsy.baseWhite"
                    />
                    <CustomText
                      text={
                        isHidden ? "**** **** **** 3934" : "3674 3927 3782 3934"
                      }
                      size="15px"
                      Weight="700"
                      color="extsy.baseWhite"
                      ls="-0.604px"
                      family="Courier Prime"
                    />
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Flex flexDir="column" gap="6.3px">
                      <CustomText
                        text={t("expiryDate")}
                        color="extsy.baseWhite"
                        size="10.7px"
                      />
                      <CustomText
                        text={isHidden ? "**/**" : "03/28"}
                        color="extsy.baseWhite"
                        size="15px"
                        weight="700"
                        ls="-0.604px"
                        family="Courier Prime"
                        cursor="pointer"
                      />
                    </Flex>
                    <Flex flexDir="column" gap="6.3px">
                      <CustomText
                        text={t("cvc")}
                        color="extsy.baseWhite"
                        size="10.7px"
                      />
                      <CustomText
                        text={isHidden ? "***" : "947"}
                        color="extsy.baseWhite"
                        size="15px"
                        weight="700"
                        ls="-0.604px"
                        family="Courier Prime"
                        cursor="pointer"
                      />
                    </Flex>
                    {isHidden ? (
                      <IconEye
                        size="20px"
                        color="#FEF8C3"
                        cursor="pointer"
                        onClick={() => {
                          setIsHidden(false);
                        }}
                      />
                    ) : (
                      <IconEyeOff
                        size="20px"
                        color="#FEF8C3"
                        cursor="pointer"
                        onClick={() => {
                          setIsHidden(true);
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  flexDir="column"
                  justify="center"
                  align="center"
                  height="100%"
                >
                  <Flex
                    align="center"
                    justify="center"
                    gap="6px"
                    height="41px"
                    width="172px"
                    bg="#FFC700"
                    borderRadius="8px"
                    backdropFilter="blur(14px)"
                    mb="40px"
                  >
                    <IconAlertTriangle size="16px" color="#0B0A0A" />
                    <CustomText
                      text={t("yourCardNotActivated")}
                      size="12px"
                      color="extsy.bg"
                      family="Titillium Web"
                    />
                  </Flex>
                </Flex>
              )}
            </Box>
          </Box>
          <Divider bg="extsy.neutral900" opacity="0.5" height="1px" />
          {isActivated ? (
            <Flex
              align="center"
              justify="space-between"
              w="100%"
              px={{ base: "16px", lg: "52px" }}
            >
              <Flex flexDir="column" gap="6px">
                <CustomText text={t("balanceAvailable")} size="12px" />
                <CustomText
                  text="$ 34,920.99"
                  size="22px"
                  ls="0.44px"
                  family="Titillium Web"
                  color="extsy.baseWhite"
                />
              </Flex>
              <Button
                display="flex"
                alignItems="center"
                gap="16px"
                height="44px"
                borderRadius="999px"
                ps="4px"
                bg="extsy.g1"
                _hover={{
                  bg: "extsy.g1",
                }}
                onClick={onTopupOpen}
              >
                <Image src={TopUpIco} alt="top-up" />
                <CustomText text={t("topUp")} size="14px" color="extsy.bg" />
              </Button>
            </Flex>
          ) : (
            <PrimaryButton
              title={t("activateNow")}
              width="159px"
              height="40px"
              onClick={() => {
                onActivateOpen();
              }}
            />
          )}
        </Flex>
      ) : (
        <Flex
          align="center"
          justify={{ base: "center", "1xl": "auto" }}
          gap="24px"
          flexDir="column"
          p="24px"
          h="100%"
          borderRadius="24px"
          bg="extsy.neutral1000"
          borderWidth="1px solid"
          borderColor="extsy.neutral1000"
          mb={{ base: "24px", lg: "0px" }}
        >
          <Image src={NoCardIco} alt="no card" />
          <Flex flexDir="column" align="center" gap="12px">
            <CustomText
              text={t("noCardAvailable")}
              size="18px"
              weight="600"
              color="extsy.baseWhite"
            />
            <CustomText
              text={t("applyForVirtualCard")}
              color="extsy.neutral300"
              size={{ base: "14px", lg: "16px" }}
            />
          </Flex>
          <PrimaryButton
            title={t("orderNowForUsd")}
            width="272px"
            onClick={onOpen}
          />
        </Flex>
      )}
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => {
          onClose();
          setIsVerified(true);
        }}
        headerText={t("createCreditCard")}
        btnText={t("completeKycNUpdagrade")}
        closeBtntext={t("close")}
        btnContinueWidth={{ lg: "336px" }}
        btnCloseWidth={{ lg: "200px" }}
        width={"600px"}
      >
        <Flex p="32px 24px" flexDir="column" gap="32px" alignItems="center">
          <Image src={CancelAlertIco} alt="cancel" />
          <CustomText
            text={t("verifyYourAccount")}
            color="extsy.baseWhite"
            size="24px"
            family="Tiltillium Web"
          />
          <CustomText
            text={t("verifyYourAccountToUnlockProFeature")}
            align="center"
            color="extsy.neutral300"
            size={{ base: "14px", lg: "16px" }}
          />
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isActiveOpen}
        onClose={onActivateClose}
        onSubmit={() => {
          onActivateClose();
          setIsActivited(true);
        }}
        headerText={"Activate Your Virtual Card"}
        btnText={"Verify & Activate Card"}
        closeBtntext={t("cancel")}
        width={"818px"}
      >
        <Flex
          mx="auto"
          p={{ base: "16px", md: "40px 24px" }}
          maxW="600px"
          flexDir="column"
          gap="32px"
          alignItems="center"
        >
          <CustomText
            text={"Enter Verification Code"}
            color="extsy.baseWhite"
            size="24px"
            family="Tiltillium Web"
          />
          <CustomText
            text={
              <>
                <span>
                  Please enter the 6-digit code sent to{" "}
                  <span
                    style={{
                      color: "#fafafa",
                    }}
                  >
                    user@email.com
                  </span>{" "}
                  to activate your card.
                </span>
              </>
            }
            align="center"
            size={{ base: "14px", md: "16px" }}
          />
          <CustomVerificationInput
            placeholder={0}
            gap={{ base: "8px", lg: "13px" }}
            w={{ base: "46px", lg: "65px" }}
          />
          <Flex flexDir="column" gap="16px">
            <CustomText
              text="Time Remaining"
              weight="600"
              color="extsy.baseWhite"
            />
            <Countdown date={Date.now() + 120 * 1000} renderer={OTPRenderer} />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isTopupOpen}
        onClose={onTopupClose}
        onSubmit={() => {
          onTopupClose();
        }}
        headerText={"Top-up"}
        btnText={"Top Up"}
        closeBtntext={t("cancel")}
        width={"700px"}
        bgtopBlur="transparent"
      >
        <Flex
          p={{ base: "16px", lg: "32px" }}
          flexDir="column"
          gap={{ base: "16px", lg: "32px" }}
          w="100%"
        >
          <Flex
            p="24px"
            borderRadius="24px"
            bg="extsy.bg"
            minH="200px"
            align="center"
            pos="relative"
            overflow="hidden"
          >
            <Flex flexDir="column" gap="24px" zIndex="1000">
              <Flex flexDir="column" gap="5px">
                <CustomText
                  text="Serra Alexandra"
                  size="15px"
                  color="extsy.baseWhite"
                />
                <CustomText
                  text="7482 3849 2839 3829"
                  size="22px"
                  weight="700"
                  family="Courier Prime"
                  color="extsy.secondary100"
                />
              </Flex>
              <Flex maxW="250px" justify="space-between" align="center">
                <Flex flexDir="column" gap="5px">
                  <CustomText text="CVC" size="15px" color="extsy.baseWhite" />
                  <CustomText
                    text="653"
                    size="22px"
                    weight="700"
                    family="Courier Prime"
                    color="extsy.secondary100"
                  />
                </Flex>
                <Flex flexDir="column" gap="5px">
                  <CustomText
                    text="Expiry Date"
                    size="15px"
                    color="extsy.baseWhite"
                  />
                  <CustomText
                    text="11/30"
                    size="22px"
                    weight="700"
                    family="Courier Prime"
                    color="extsy.secondary100"
                  />
                </Flex>
              </Flex>
            </Flex>
            <Box
              h="245px"
              w="696px"
              borderRadius="696px"
              opacity="0.6"
              bg="extsy.primary500"
              filter="blur(97px)"
              transform="rotate(-120deg)"
              pos="absolute"
              right={{ base: "-367px", lg: "-187px" }}
              top="-276px"
            />
            <Image
              src={VirtualCardDummy}
              alt="virtual-card"
              pos="absolute"
              right="0"
              mr="20px"
              bottom="0"
              display={{ base: "none", lg: "block" }}
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin Name" size="14px" />
            <CustomSelect placeholder="USDT" ico={USDTIco} />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Amount" size="14px" />
            <VCardInput placeholder="0.00" bg="extsy.neutral900" />
          </Flex>
        </Flex>
      </CustomModal>
    </>
  );
}

const OTPRenderer = ({ hours, minutes, seconds, completed }: any) => {
  return (
    <Flex align="center" gap="8px" justify="center">
      <Flex
        align="center"
        justify="center"
        h="40px"
        w="41px"
        borderRadius="8px"
        bg="extsy.neutral900"
      >
        <CustomText
          text={minutes < 10 ? "0" + minutes : minutes}
          weight="500"
          color="extsy.primary"
        />
      </Flex>
      <CustomText text=":" weight="500" color="extsy.primary" />
      <Flex
        align="center"
        justify="center"
        h="40px"
        w="41px"
        borderRadius="8px"
        bg="extsy.neutral900"
      >
        <CustomText
          text={seconds < 10 ? "0" + seconds : seconds}
          weight="500"
          color="extsy.primary"
        />
      </Flex>
    </Flex>
  );
};

export default OrderNowCard;
