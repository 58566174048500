import {
  Avatar,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useTranslation } from "react-i18next";
import GradientText from "../../components/text/GradientText";
import TableSearchInput from "../../components/form/TableSearchInput";
import { LIFETIME_EARNINGS } from "../../utils/constants";
import SilverTrophy from "../../assets/images/silver-trophy.svg";
import BronzeTrophy from "../../assets/images/bronze-trophy.svg";
import GoldTrophy from "../../assets/images/trophy-cup.svg";
import OtherTrophy from "../../assets/images/dummy-trophy-ico.svg";

function LifeTimeEarnings() {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap={{ base: "16px", lg: "24px" }} w="100%">
      <Flex
        align="center"
        justify="space-between"
        flexDir={{
          base: "column",
          md: "row",
        }}
        gap="16px"
      >
        <CustomText
          text="Lifetime Earnings Leaderboard: Top EXTSY Raffle Winners"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          lh={{ lg: "34px" }}
          family="Titillium Web"
          color="extsy.baseWhite"
        />
        <TableSearchInput
          placeholder="Search Raffle"
          minW={{ base: "100%", md: "250px" }}
          maxW={{ base: "100%", md: "250px" }}
        />
      </Flex>
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Place"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "100px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Username"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "100px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Winning Prize"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {LIFETIME_EARNINGS?.map((earning: any, index: number) => (
              <Tr key={index}>
                <Td
                  height="54px"
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  py="0"
                >
                  <Flex align="center" gap="6px">
                    <Flex
                      height="32px"
                      width="32px"
                      align="center"
                      justify="center"
                      bg="extsy.bg"
                      borderRadius="8px"
                    >
                      <Image
                        src={
                          index === 0
                            ? GoldTrophy
                            : index === 1
                            ? SilverTrophy
                            : index === 2
                            ? BronzeTrophy
                            : OtherTrophy
                        }
                        alt="trophy"
                        h="18px"
                        w="13px"
                      />
                    </Flex>
                    <CustomText
                      text={earning?.place}
                      size="14px"
                      weight="500"
                    />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" gap="12px">
                    <Avatar boxSize="32px" src="" />
                    <CustomText text={earning?.name} size="14px" weight="500" />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText text={earning?.prize} size="14px" weight="500" />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LifeTimeEarnings;
