import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import CustomText from "../../components/text/CustomText";
import GradientText from "../../components/text/GradientText";
import TrophyBlur from "../../assets/images/trophy-blur.svg";
import NextDraw from "./NextDraw";
import TableSearchInput from "../../components/form/TableSearchInput";
import {
  IconCalendar,
  IconCheck,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";
import CustomCalendar from "../../components/form/CustomCalendar";
import MyRaffleHistory from "./MyRafflesHistory";
import LifeTimeEarnings from "./LifeTimeEarnings";
import Tickets from "./Tickets";

function Raffle() {
  const filterRef = useRef(null);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [date, setDate] = useState("10-08-2024");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");

  const formatedDate = () => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  const FILTER_ITEMS = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "won",
      name: "Won",
    },
    {
      id: "lost",
      name: "Lost",
    },
    {
      id: "pending",
      name: "Draw Pending",
    },
  ];

  return (
    <Flex gap={{ base: "16px", lg: "24px" }} flexDir="column">
      <Flex
        flexDir={{ base: "column", "1xl": "row" }}
        justify="space-between"
        gap={{ base: "16px", lg: "40px" }}
        p={{ base: "16px", lg: "40px", "2xl": "40px 56px" }}
        bg="extsy.neutral1000"
        border="1px solid"
        borderColor="extsy.neutral1000"
        borderRadius={{ base: "16px", lg: "24px" }}
        pos="relative"
      >
        <Flex
          flexDir="column"
          gap={{ base: "40px", lg: "24px" }}
          justify="space-between"
          maxW={{ "1xl": "859px" }}
        >
          <CustomText
            text="Take a chance and win big! Explore your previous raffles and join the next draw."
            size={{ base: "18px", lg: "28px" }}
            family="Titillium Web"
            color="extsy.neutral50"
          />
          <Flex
            gap={{ base: "16px", lg: "40px" }}
            align="center"
            wrap="wrap"
            justify={{ base: "space-between", lg: "flex-start" }}
          >
            <Flex flexDir="column" gap="8px">
              <CustomText text="Prize" size="14px" color="extsy.baseWhite" />
              <GradientText
                text="$ 21,028.00"
                size="18px"
                weight="600"
                bg="extsy.g1"
              />
            </Flex>
            <Box
              width="1px"
              height="100%"
              bg="extsy.neutral900"
              display={{ base: "none", lg: "block" }}
            />
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Total Ticket Sold"
                size="14px"
                color="extsy.baseWhite"
              />
              <GradientText text="302" size="18px" weight="600" bg="extsy.g1" />
            </Flex>
            <Box
              width="1px"
              height="100%"
              bg="extsy.neutral900"
              display={{ base: "none", lg: "block" }}
            />
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Entry Date"
                size="14px"
                color="extsy.baseWhite"
              />
              <GradientText
                text="05-09-2024"
                size="18px"
                weight="600"
                bg="extsy.g1"
              />
            </Flex>
          </Flex>
        </Flex>
        <NextDraw />
        <Image src={TrophyBlur} alt="trophy" pos="absolute" top="0" right="0" />
      </Flex>
      <Flex
        gap={{ base: "16px", lg: "24px" }}
        w="100%"
        flexDir={{ base: "column", "1xl": "row" }}
      >
        <Flex
          p={{ base: "16px", lg: "24px" }}
          bg="extsy.neutral1000"
          borderRadius={{ base: "16px", lg: "24px" }}
          flexDir="column"
          boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
          w="100%"
          maxW="1290px"
          gap={{ base: "16px", lg: "24px" }}
          order={{ base: 2, "1xl": 1 }}
        >
          <Flex
            flexDir={{
              lg: "row",
              base: "column",
            }}
            align={{ lg: "center" }}
            justify="space-between"
            gap="10px"
          >
            <CustomText
              text="Your Raffles"
              size={{ base: "18px", lg: "24px" }}
              weight={{ base: "600", lg: "700" }}
              lh={{ lg: "34px" }}
              family="Titillium Web"
              color="extsy.baseWhite"
            />
            <Flex
              gap="10px"
              align="center"
              flexDir={{
                sm: "row",
                base: "column",
              }}
            >
              <TableSearchInput
                placeholder="Search Raffle"
                minW={{
                  md: "250px",
                }}
              />
              <Flex gap="10px" align="center">
                <Box pos="relative">
                  <Flex
                    cursor="pointer"
                    gap="16px"
                    align="center"
                    borderRadius="666px"
                    bg="extsy.neutral900"
                    boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                    height="38px"
                    ps="24px"
                    pe="10px"
                    onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                  >
                    <CustomText
                      cursor
                      text={formatedDate()}
                      size="12px"
                      color="extsy.baseWhite"
                    />
                    <IconCalendar size="16px" color="#fafafa" />
                  </Flex>
                  <CustomCalendar
                    isCalendarOpen={isCalendarOpen}
                    value={date}
                    onChange={(val: any) => {
                      setDate(val);
                      setIsCalendarOpen(false);
                    }}
                  />
                </Box>
                <Box pos="relative">
                  <Flex
                    height="38px"
                    minW="149px"
                    align="center"
                    justify="space-between"
                    cursor="pointer"
                    borderRadius="666px"
                    bg="extsy.neutral900"
                    boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                    ps="24px"
                    pe="10px"
                    onClick={() => {
                      setIsFilterOpen(!isFilterOpen);
                    }}
                  >
                    <CustomText
                      cursor="pointer"
                      text={
                        FILTER_ITEMS.find((item) => item.id === activeFilter)
                          ?.name
                      }
                      size="12px"
                      color="extsy.baseWhite"
                    />
                    {isFilterOpen ? (
                      <IconChevronUp color="#FAFAFA" size="16px" />
                    ) : (
                      <IconChevronDown color="#FAFAFA" size="16px" />
                    )}
                  </Flex>
                  {isFilterOpen && (
                    <Flex
                      flexDir="column"
                      gap="12px"
                      p="16px"
                      borderRadius="16px"
                      border="1px solid"
                      borderColor="extsy.neutral800"
                      bg="extsy.neutral900"
                      minW="150px"
                      pos="absolute"
                      top="0"
                      marginTop="50px"
                      zIndex="100"
                      ref={filterRef}
                    >
                      {FILTER_ITEMS.map((item, index) => (
                        <Flex
                          align="center"
                          justify="space-between"
                          py="8px"
                          cursor="pointer"
                          key={index}
                          onClick={() => {
                            setIsFilterOpen(false);
                            setActiveFilter(item.id);
                          }}
                        >
                          <CustomText
                            text={item?.name}
                            size="14px"
                            color={
                              item?.id === activeFilter
                                ? "extsy.baseWhite"
                                : "extsy.neutral300"
                            }
                            cursor="pointer"
                          />
                          {item?.id === activeFilter && (
                            <IconCheck size="16px" color="#FAFAFA" />
                          )}
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <MyRaffleHistory />
        </Flex>
        <Box order={{ base: 1, "1xl": 2 }}>
          <Tickets />
        </Box>
      </Flex>
      <Box
        p={{ base: "16px", lg: "24px" }}
        bg="extsy.neutral1000"
        boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03);"
        borderRadius={{base:"16px",lg:"24px"}}
      >
        <LifeTimeEarnings />
      </Box>
    </Flex>
  );
}

export default Raffle;
