import { Box, Circle, Flex, Image, Input } from "@chakra-ui/react";
import React, { useRef } from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import ProfileImage from "../../assets/images/profile-img.svg";
import { IconPhotoPlus, IconX } from "@tabler/icons-react";
import PartnerHand from "../../assets/images/partner-hand.svg";

function RaffleAffliate() {
  const profileInputRef = useRef<HTMLInputElement>(null);

  return (
    <Flex
      gap={{ base: "16px", lg: "24px" }}
      w="100%"
      flexDir={{ base: "column", "1xl": "row" }}
    >
      <Flex
        gap={{ base: "16px", lg: "29px" }}
        p={{ base: "16px", lg: "32px" }}
        flexDir="column"
        borderRadius={{ base: "16px", lg: "24px" }}
        bg="extsy.neutral900"
        border="1px solid"
        borderColor="extsy.neutral900"
        w="100%"
        pos="relative"
        overflow="hidden"
      >
        <CustomText
          text="Raffle Name / Avatar"
          size="24px"
          lh="34px"
          weight="700"
          family="Titillium Web"
          color="extsy.white"
        />
        <Flex flexDir="column" gap="16px" zIndex="100">
          <CustomText
            text="Name"
            family="Titillium Web"
            color="extsy.baseWhite"
          />
          <Input
            fontSize="16px"
            fontWeight="400"
            color="extsy.baseWhite"
            fontFamily={"Titillium Web"}
            h="56px"
            bg="extsy.neutral1000"
            borderRadius="8px"
            px="22px"
            border="none"
            _hover={{
              border: "none",
            }}
            _focus={{
              border: "none",
            }}
            _focusVisible={{ border: "none" }}
          />
          <CustomText
            text="Avatar"
            family="Titillium Web"
            color="extsy.baseWhite"
            mt="8px"
          />
          <Flex
            gap={{ base: "16px", lg: "32px" }}
            align="center"
            flexDir={{ base: "column", lg: "row" }}
          >
            <Box pos="relative" minH="156px" minW="156px">
              <Image
                src={ProfileImage}
                alt="profile"
                boxSize="156px"
                draggable={false}
              />
              <Circle
                bg="extsy.primary500"
                border="5px solid"
                borderColor="extsy.neutral900"
                pos="absolute"
                size="34px"
                top="0"
                right="0"
                mt="-13px"
                mr="-13px"
              >
                <IconX size="14px" color="#FAFAFA" />
              </Circle>
            </Box>
            <Flex
              flexDir="column"
              gap="16px"
              align="center"
              justify="center"
              minH="156px"
              p="32px"
              borderRadius="12px"
              bg="rgba(11, 10, 10, 0.60)"
              border="1px dashed"
              borderColor="extsy.neutral700"
              w="100%"
              cursor="pointer"
              onClick={() => profileInputRef.current?.click()}
            >
              <IconPhotoPlus size="32px" color="#BAB8B8" />
              <CustomText
                text="Browse or Drag and Drop an image here"
                size="14px"
                cursor
              />
            </Flex>
          </Flex>
        </Flex>
        <PrimaryButton
          title="Save Changes"
          size="14px"
          maxW={{ lg: "156px" }}
        />

        <Box
          h="447px"
          w="447px"
          borderRadius="447px"
          opacity="0.5"
          bg="extsy.primary500"
          filter="blur(82.5px)"
          pos="absolute"
          bottom="0"
          right="0"
          mr="-258px"
          mb="-303px"
          zIndex="1"
        />
      </Flex>
      <Flex minW={{ lg: "716px" }} flexDir="column" gap="24px">
        <Flex
          gap={{ base: "24px", lg: "40px" }}
          p={{ base: "16px", lg: "32px" }}
          flexDir="column"
          borderRadius={{ base: "16px", lg: "24px" }}
          bg="extsy.neutral900"
          border="1px solid"
          borderColor="extsy.neutral900"
          w="100%"
          pos="relative"
          overflow="hidden"
          minH={{ lg: "245px" }}
        >
          <Box zIndex="100">
            <CustomText
              text="Affiliation"
              size="24px"
              lh="34px"
              weight="700"
              family="Titillium Web"
              color="extsy.white"
            />
          </Box>
          <Flex flexDir="column" gap="16px" zIndex="100">
            <CustomText text="Invite your friends and earn rewards." />
            <Flex
              gap="10px"
              flexDir={{
                md: "row",
                base: "column",
              }}
            >
              <Input
                fontSize="16px"
                fontWeight="400"
                color="extsy.baseWhite"
                fontFamily={"Titillium Web"}
                h="56px"
                bg="extsy.neutral1000"
                borderRadius="8px"
                px="22px"
                border="none"
                _hover={{
                  border: "none",
                }}
                _focus={{
                  border: "none",
                }}
                _focusVisible={{ border: "none" }}
                value="www.extsy.com/affilitiate-program233618"
              />
              <PrimaryButton
                title="Share & Invite friends"
                size="14px"
                maxW={{ md: "178px" }}
              />
            </Flex>
          </Flex>

          <Box
            h="447px"
            w="447px"
            borderRadius="447px"
            opacity="0.5"
            bg="extsy.neutral700"
            filter="blur(82.5px)"
            pos="absolute"
            bottom="0"
            left="0"
            ml="-22px"
            mb="-73px"
            zIndex="1"
          />
        </Flex>
        <Flex
          gap="40px"
          p="32px"
          flexDir="column"
          borderRadius="24px"
          bg="extsy.neutral900"
          border="1px solid"
          borderColor="extsy.neutral900"
          w="100%"
          pos="relative"
          overflow="hidden"
          minH="245px"
        >
          <Box zIndex="100">
            <CustomText
              text="Partner"
              size="24px"
              lh="34px"
              weight="700"
              family="Titillium Web"
              color="extsy.white"
            />
          </Box>
          <Flex flexDir="column" gap="16px" zIndex="100">
            <CustomText text="Invite your friends and earn rewards." />
            <PrimaryButton title="Become a Partner" maxW={{ md: "155px" }} />
          </Flex>

          <Image
            src={PartnerHand}
            alt="partner"
            pos="absolute"
            right="0"
            top="0"
            zIndex="10"
            display={{ base: "none", md: "block" }}
          />

          <Box
            h="674px"
            w="674px"
            borderRadius="674px"
            bg="#15B097"
            filter="blur(82.5px)"
            opacity="0.8"
            pos="absolute"
            bottom="0"
            right="0"
            mr={{ base: "-500px", md: "-316px" }}
            mb="-485px"
            zIndex="1"
          />
        </Flex>
      </Flex>
      <input type="file" hidden ref={profileInputRef} />
    </Flex>
  );
}

export default RaffleAffliate;
