import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useTranslation } from "react-i18next";
import GradientText from "../../components/text/GradientText";
import { MY_RAFFLES } from "../../utils/constants";
import TrophyCup from "../../assets/images/trophy-cup.svg";

function MyRaffleHistory() {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Raffle ID"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="260px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Ticket Number"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Date and Time"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Prize"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="283px" minW="140px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Outcome"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {MY_RAFFLES?.map((raffle: any, index: number) => (
              <Tr key={index}>
                <Td
                  height="54px"
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  py="0"
                  pos="relative"
                  overflow="hidden"
                >
                  <CustomText text={raffle?.id} size="14px" weight="500" />
                  {raffle?.isActive && (
                    <Box
                      h="53px"
                      w="53px"
                      borderRadius="53px"
                      filter="blur(20px)"
                      bg="extsy.primary500"
                      pos="absolute"
                      left="0"
                      bottom="0"
                      ml="-36px"
                    />
                  )}
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" gap="12px">
                    {raffle?.tickets?.map((ticket: any, idx: number) => (
                      <>
                        <Flex align="center" gap="8px">
                          <CustomText
                            key={idx}
                            text={ticket?.id}
                            size="14px"
                            weight="500"
                            color={ticket?.isWon ? "extsy.baseWhite" : ""}
                          />
                          {ticket?.isWon && (
                            <Image
                              src={TrophyCup}
                              alt="trophy-cup"
                              h="16px"
                              w="12px"
                            />
                          )}
                        </Flex>
                        {raffle?.tickets?.length - 1 !== idx && (
                          <Box w="1px" h="16px" bg="extsy.neutral800" />
                        )}
                        {/* <Box w="1px" h="16px" bg="extsy.neutral800" /> */}
                      </>
                    ))}
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText
                    text={raffle?.createdAt}
                    size="14px"
                    weight="500"
                  />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText text={raffle?.prize} size="14px" weight="500" />
                </Td>
                {raffle?.isWon ? (
                  <Td
                    borderColor="extsy.neutral900"
                    borderRight="1px solid"
                    overflow="hidden"
                    pos="relative"
                  >
                    <Flex justify="space-between" align="center">
                      <GradientText
                        text={"Won"}
                        size="14px"
                        weight="500"
                        bg="extsy.g1"
                      />
                      <Image
                        src={TrophyCup}
                        alt="trophy-cup"
                        h="22px"
                        w="17px"
                      />
                    </Flex>
                    <Box
                      w="63px"
                      h="63px"
                      pos="absolute"
                      borderRadius="63px"
                      filter="blur(20px)"
                      bg="#15B097"
                      bottom="0"
                      right="0"
                      mb="-45px"
                      mr="-18px"
                    />
                  </Td>
                ) : (
                  <Td borderColor="extsy.neutral900" borderRight="1px solid">
                    <CustomText
                      text={raffle?.isActive ? raffle?.outcome : "Lost"}
                      size="14px"
                      weight="500"
                    />
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default MyRaffleHistory;
