import { Box, Flex, Image, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import BTCIco from "../../assets/images/btc-ico.svg";
import ArrowDown from "../../assets/images/arrow-down-circle.svg";
import { COINS } from "../../utils/constants";
import { IconCheck } from "@tabler/icons-react";

function AMLCard() {
  const [activeCoin, setActiveCoin] = useState({
    symbol: "BTC",
    icon: BTCIco,
    name: "Bitcoin",
  });
  const [isCoinOpen, setIsCoinOpen] = useState(false);

  return (
    <Flex
      minH={{ lg: "406px" }}
      width="100%"
      p={{ base: "16px", lg: "24px" }}
      flexDir="column"
      gap={{ base: "16px", lg: "40px" }}
      justify="space-between"
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral900"
      bg="extsy.neutral1000"
    >
      <Flex gap={{ base: "16px", lg: "40px" }} flexDir="column">
        <CustomText
          text="AML Check"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          family="Titillium Web"
          color="extsy.baseWhite"
        />
        <CustomText text="By using the AML service you can check suspicious wallets for illegal activity. This will help you protect your money from scammers." />
        <Flex
          p="24px"
          bg="extsy.neutral900"
          borderRadius="16px"
          gap={{ base: "16px", lg: "32px" }}
          flexDir={{
            base: "column",
            lg: "row",
          }}
        >
          <Flex flexDir="column" gap="10px" width="100%">
            <CustomText text="Enter Address" size="12px" color="extsy.white" />
            <Input
              bg="transparent"
              pl="0"
              width="100%"
              height="30px"
              fontSize="18px"
              fontFamily="Metropolis"
              color="extsy.white"
              border="none"
              borderRadius="0px"
              borderBottom="1px solid"
              borderColor="extsy.white"
              _hover={{
                borderColor: "extsy.white",
              }}
              _focus={{
                borderColor: "extsy.white",
              }}
              _focusVisible={{
                borderColor: "extsy.white",
              }}
            />
          </Flex>
          <Box pos="relative">
            <Flex
              justify="space-between"
              gap="12px"
              align="center"
              height="44px"
              width={{ lg: "160px" }}
              cursor="pointer"
              px="8px"
              borderRadius="8px"
              border="1px solid"
              borderColor="extsy.neutral800"
              onClick={() => setIsCoinOpen(!isCoinOpen)}
            >
              <Flex gap="12px" align="center">
                <Image src={activeCoin?.icon} alt="btc" boxSize="28px" />
                <CustomText
                  text={activeCoin?.symbol}
                  weight="600"
                  color="extsy.baseWhite"
                  cursor
                />
              </Flex>
              <Image src={ArrowDown} alt="arrow" boxSize="24px" />
            </Flex>
            <Flex
              flexDir="column"
              gap="16px"
              p="16px"
              bg="#131313"
              borderRadius="16px"
              border="1px solid"
              borderColor="neutral800"
              pos="absolute"
              width={{ base: "100%", lg: "163px" }}
              top="0"
              right="0"
              mt="50px"
              zIndex="1000"
              display={isCoinOpen ? "flex" : "none"}
            >
              {COINS.map((coin, index) => (
                <Flex
                  key={index}
                  gap="12px"
                  align="center"
                  justify="space-between"
                  cursor="pointer"
                  onClick={() => {
                    setActiveCoin(coin);
                    setIsCoinOpen(false);
                  }}
                >
                  <Flex gap="10px" align="center">
                    <Image src={coin.icon} alt={coin.symbol} boxSize="24px" />
                    <CustomText
                      text={coin.symbol}
                      color={
                        coin.symbol === activeCoin?.symbol
                          ? "extsy.baseWhite"
                          : ""
                      }
                      cursor
                    />
                  </Flex>
                  {coin.symbol === activeCoin?.symbol && (
                    <IconCheck size="16px" color="#FAFAFA" />
                  )}
                </Flex>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <PrimaryButton title="Check Address" maxW={{ md: "272px" }} />
    </Flex>
  );
}

export default AMLCard;
