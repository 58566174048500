import { Button } from "@chakra-ui/react";
import React from "react";

function CloseButton({
  title,
  height,
  width,
  maxW,
  onClick,
  isLoading,
  isDisabled,
  padding,
  family,
  order,
}: any) {
  return (
    <Button
      height={height || { base: "48px", lg: "56px" }}
      width={width || "100%"}
      maxW={maxW || "100%"}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      display="flex"
      flexDir="column"
      padding={padding ? padding : "10px 32px"}
      alignItems="center"
      borderRadius="999px"
      color={"extsy.baseWhite"}
      bg="extsy.bgClose"
      _hover={{
        bg: "extsy.bgClose",
      }}
      fontFamily={family || "Titillium Web"}
      order={order}
    >
      {title}
    </Button>
  );
}

export default CloseButton;
