import { Box, Button, Circle, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import GradientText from "../text/GradientText";
import ExtsyCircleIco from "../../assets/images/extsy-circle-ico.svg";
import SwapIco from "../../assets/images/swap-notification-ico.svg";
import WinIco from "../../assets/images/raffle-win-ico.svg";

function Notifications() {
  const { t } = useTranslation();
  return (
    <Box
      pos="absolute"
      // maxW="513px"
      w={{ base: "310px", sm: "358px", lg: "513px" }}
      p={{ base: "24px 16px", lg: "24px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral900"
      bg="extsy.neutral1000"
      top="0"
      mt="50px"
      right="0"
      mr={{ base: "-40px", sm: "0" }}
      zIndex="10000"
    >
      <Flex justify="space-between" align="center" pb="12px">
        <Flex align="center" gap="6px">
          <CustomText
            text={t("notifications")}
            color="extsy.baseWhite"
            weight="600"
          />
          <Circle bg="extsy.primary500" size="24px">
            <CustomText text="2" color="extsy.white" size="14px" />
          </Circle>
        </Flex>
        <GradientText
          text={t("markAllAsRead")}
          size="12px"
          weight="500"
          bg="extsy.g1"
          cursor="pointer"
        />
      </Flex>
      <Box overflowY={"auto"} maxH="383px">
        <Flex
          gap="12px"
          p={{ base: "16px 0px", lg: "16px 16px 16px 24px" }}
          borderTop="1px solid"
          borderColor="extsy.neutral900"
        >
          <Image src={WinIco} alt="win" boxSize="32px" borderRadius="50%" />
          <Flex flexDir="column" gap="12px">
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Raffle of the month August"
                size="14px"
                weight="600"
                color="extsy.baseWhite"
              />
              <CustomText
                text={
                  <span>
                    You have won{" "}
                    <span style={{ color: "#fff", fontSize: "12px" }}>
                      $54,978.00
                    </span>{" "}
                    prize. collect now.
                  </span>
                }
                size="12px"
                weight="600"
              />
            </Flex>
            <Button
              bg="extsy.g1"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p="0"
              height="32px"
              width="108px"
              borderRadius="8px"
              _hover={{
                bg: "extsy.g1",
              }}
            >
              <CustomText
                text={t("collectPrize")}
                size="14px"
                weight="500"
                color="extsy.bg"
              />
            </Button>
            <CustomText text="8 min ago" size="12px" color="extsy.neutral300" />
          </Flex>
        </Flex>
        <Flex
          gap="12px"
          p={{ base: "16px 0px", lg: "16px 16px 16px 24px" }}
          borderTop="1px solid"
          borderColor="extsy.neutral900"
        >
          <Image src={SwapIco} alt="win" boxSize="32px" borderRadius="50%" />
          <Flex flexDir="column" gap="12px">
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Swap Crypto"
                size="14px"
                weight="600"
                color="extsy.baseWhite"
              />
              <CustomText
                text={
                  <span>
                    You have swapped{" "}
                    <span style={{ color: "#fff", fontSize: "12px" }}>
                      5 BTC
                    </span>{" "}
                    in to{" "}
                    <span style={{ color: "#fff", fontSize: "12px" }}>
                      USDT
                    </span>
                  </span>
                }
                size="12px"
                weight="600"
              />
            </Flex>
            <CustomText
              text="45 min ago"
              size="12px"
              color="extsy.neutral300"
            />
          </Flex>
        </Flex>
        <Flex
          gap="12px"
          p={{ base: "16px 0px", lg: "16px 16px 16px 24px" }}
          borderTop="1px solid"
          borderColor="extsy.neutral900"
        >
          <Image
            src={ExtsyCircleIco}
            alt="win"
            boxSize="32px"
            borderRadius="50%"
          />
          <Flex flexDir="column" gap="12px">
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Extsy Support"
                size="14px"
                weight="600"
                color="extsy.baseWhite"
              />
              <CustomText
                text={
                  <span>
                    Responded to your ticket{" "}
                    <span style={{ color: "#fff", fontSize: "12px" }}>
                      #36472
                    </span>{" "}
                  </span>
                }
                size="12px"
                weight="600"
              />
            </Flex>
            <Flex gap="12px" align="center">
              <Box border="1px solid #FEF9CC" py="8px" />
              <CustomText
                text="We have received your case our team is working on it."
                color="extsy.neutral300"
                size="12px"
                weight="500"
              />
            </Flex>
            <CustomText text="1 day ago" size="12px" color="extsy.neutral300" />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default Notifications;
