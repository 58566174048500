import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import { useTranslation } from "react-i18next";
import TableSearchInput from "../../components/form/TableSearchInput";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { BALANCES } from "../../utils/constants";
import CardIco from "../../assets/images/dashboard/virtual-inactive.svg";
import SwapIco from "../../assets/images/dashboard/swap-inactive.svg";
import GradientText from "../../components/text/GradientText";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";

function BalanceTracker() {
  const { t } = useTranslation();

  const [isConnected, setIsConnected] = useState(
    localStorage.getItem("isConnected") || false
  );

  return (
    <Flex
      flexDir="column"
      justify="space-between"
      p={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      borderRadius="24px"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      minH="calc(100vh - 198px)"
    >
      <Box>
        <Flex align="center" justify="space-between" pb="20px">
          <CustomText
            text={t("balance")}
            family="Titillium Web"
            size={{ base: "18px", lg: "20px" }}
            color="extsy.baseWhite"
            ls="1px"
          />
          <TableSearchInput
            placeholder={t("searchYourAsset")}
            minW={{ base: "130px", lg: "250px" }}
            maxW={{ base: "130px", lg: "250px" }}
          />
        </Flex>
        {isConnected ? (
          <TableContainer minH="406px">
            <Table border="1px solid" color="extsy.neutral900">
              <Thead bg="extsy.neutral900" height="40px">
                <Tr>
                  <Th borderColor="extsy.neutral900" maxW="583px" minW="260px">
                    <Flex align="center" gap="4px">
                      <CustomText
                        text={t("coinNUsdValue")}
                        size="13px"
                        color="extsy.baseWhite"
                        weight="500"
                        ls="-0.13px"
                      />
                      <Flex flexDir="column">
                        <IconChevronUp
                          cursor="pointer"
                          color="#FAFAFA"
                          size="14px"
                        />
                        <IconChevronDown
                          color="#FAFAFA"
                          size="14px"
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Th>
                  <Th borderColor="extsy.neutral900" maxW="583px" minW="260px">
                    <Flex align="center" gap="4px">
                      <CustomText
                        text={t("amount")}
                        size="13px"
                        color="extsy.baseWhite"
                        weight="500"
                        ls="-0.13px"
                      />
                      <Flex flexDir="column">
                        <IconChevronUp
                          cursor="pointer"
                          color="#FAFAFA"
                          size="14px"
                        />
                        <IconChevronDown
                          color="#FAFAFA"
                          size="14px"
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Th>
                  <Th borderColor="extsy.neutral900" maxW="283px" minW="230px">
                    <Flex align="center" gap="4px">
                      <CustomText
                        text={t("actions")}
                        size="13px"
                        color="extsy.baseWhite"
                        weight="500"
                        ls="-0.13px"
                      />
                      <Flex flexDir="column">
                        <IconChevronUp
                          cursor="pointer"
                          color="#FAFAFA"
                          size="14px"
                        />
                        <IconChevronDown
                          color="#FAFAFA"
                          size="14px"
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {BALANCES.map((balance, index) => (
                  <Tr key={index}>
                    <Td
                      height="54px"
                      borderColor="extsy.neutral900"
                      borderRight="1px solid"
                      py="0"
                    >
                      <Flex align="center" justify="space-between">
                        <Flex gap="16px" align="center">
                          <Image
                            src={balance.icon}
                            alt={balance?.symbol}
                            boxSize="32px"
                          />
                          <CustomText
                            text={balance.symbol}
                            size="14px"
                            color="extsy.baseWhite"
                          />
                        </Flex>
                        <Flex gap="8px" align="center">
                          <CustomText
                            text={balance?.usdValue}
                            size="14px"
                            color="extsy.neutral100"
                          />
                          <Flex align="center" gap="2px">
                            <CustomText
                              text={`(${balance?.percentage})`}
                              size="14px"
                              color={
                                balance?.percentage?.includes("-")
                                  ? "#ED3D4E"
                                  : "#15B097"
                              }
                              ls="1px"
                            />
                            {balance?.percentage?.includes("-") ? (
                              <IconChevronDown color="#ED3D4E" size="14px" />
                            ) : (
                              <IconChevronUp color="#15B097" size="14px" />
                            )}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex align="center" justify="space-between">
                        <CustomText text={balance.amount} size="14px" />
                        <CustomText
                          text={`~$${balance.amountUsd}`}
                          size="14px"
                          color="extsy.neutral600"
                        />
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex gap="16px" align="center">
                        <Flex cursor="pointer" gap="4px" align="center">
                          <Image
                            src={CardIco}
                            alt="buy"
                            height="18px"
                            width="18px"
                          />
                          <CustomText
                            text={t("buy")}
                            size="10px"
                            color="extsy.baseWhite"
                            cursor="pointer"
                          />
                        </Flex>
                        <Box bg="extsy.neutral900" height="9px" width="1px" />
                        <Flex cursor="pointer" gap="4px" align="center">
                          <Image
                            src={SwapIco}
                            alt="swap"
                            height="18px"
                            width="18px"
                          />
                          <CustomText
                            text={t("swap")}
                            size="10px"
                            color="extsy.baseWhite"
                            cursor="pointer"
                          />
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Flex
            align="center"
            justify="center"
            flexDir="column"
            gap="24px"
            minH="401px"
          >
            <Image src={EmptyTrxIco} alt="empty" />
            <CustomText
              text={t("noBalanceAvailable")}
              color="extsy.neutral400"
            />
            <PrimaryButton
              title={t("connectWallet")}
              width={{ base: "190px", lg: "250px" }}
              height={{ base: "48px", lg: "56px" }}
              onClick={() => {
                localStorage.setItem("isConnected", "true");
                setIsConnected(true);
              }}
            />
          </Flex>
        )}
      </Box>
      {isConnected && (
        <Flex gap="16px" px="20px" py="14px" align="center" justify="flex-end">
          <CustomText
            text={`1-10 ${t("of")} 50`}
            size="13px"
            weight="500"
            color="extsy.neutral300"
          />
          <Flex gap="8px">
            <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              bg="extsy.neutral900"
              borderRadius="6px"
              height="30px"
              width="30px"
            >
              <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
            </Flex>
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              bg="extsy.neutral900"
              borderRadius="6px"
              height="30px"
              width="30px"
            >
              <CustomText text="2" size="14px" weight="500" />
            </Flex>
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              bg="extsy.neutral900"
              borderRadius="6px"
              height="30px"
              width="30px"
            >
              <CustomText text="3" size="14px" weight="500" />
            </Flex>
            <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default BalanceTracker;
