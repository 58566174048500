import React from "react";
import VirtualCards from "../../components/cards/virtualCards";
import DetailCard from "../../components/cards/DetailCard";
import RecentTransactions from "../../components/dashboard/RecentTransactions";
import { Grid, GridItem } from "@chakra-ui/react";

function VirtualCard() {
  return (
    <Grid
      gap={{ base: "16px", lg: "24px" }}
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(12, 1fr)"
    >
      <GridItem colSpan={{ base: 12, xl: 8 }}>
        <VirtualCards />
      </GridItem>
      <GridItem colSpan={{ base: 12, xl: 4 }}>
        <DetailCard />
      </GridItem>
      <GridItem colSpan={{ base: 12, xl: 8 }}>
        <RecentTransactions minH={"201px"} mt={{ xl: "-26.2rem" }} />
      </GridItem>
    </Grid>
  );
}

export default VirtualCard;
