import { Button } from "@chakra-ui/react";
import React from "react";

function PrimaryButton({
  title,
  height,
  width,
  maxW,
  onClick,
  isLoading,
  isDisabled,
  padding,
  family,
  size,
  icon,
  order,
}: any) {
  return (
    <Button
      height={height || { base: "48px", lg: "56px" }}
      width={width || "100%"}
      maxW={maxW || "100%"}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      padding={padding ? padding : ""}
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={{ base: "8px", lg: "10px" }}
      borderRadius="999px"
      bg="extsy.g1"
      _hover={{
        bg: "extsy.g1",
      }}
      fontFamily={family || "Titillium Web"}
      fontSize={size || "16px"}
      order={order}
    >
      {icon && <div>{icon}</div>}
      {title}
    </Button>
  );
}

export default PrimaryButton;
