import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";

function ExtsyStats() {
  const { t } = useTranslation();
  return (
    <Flex
      flexDir="column"
      gap={{ base: "40px", md: "40px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral1000"
      bg="extsy.neutral1000"
      p={{ base: "16px", lg: "32px" }}
      pos="relative"
      overflow="hidden"
    >
      <Box zIndex="1000">
        <CustomText
          text={t("stats")}
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          color="extsy.white"
        />
      </Box>
      <Flex
        gap={{ base: "16px", lg: "40px" }}
        align={{ lg: "center" }}
        zIndex="1000"
        wrap={{ base: "wrap", lg: "nowrap" }}
        // flexDir={{
        //   lg: "row",
        //   base: "column",
        // }}
      >
        <Flex flexDir="column" gap="8px" w={{ base: "230px", lg: "100%" }}>
          <CustomText
            text={t("totalEarned")}
            color="extsy.baseWhite"
            size="14px"
          />
          <CustomText
            text={"0.00 BTC"}
            size={{ base: "24px", lg: "32px" }}
            weight={{ base: "600", lg: "700" }}
            color="extsy.baseWhite"
          />
          <CustomText text={"~ $ 0.00"} size="14px" color="extsy.baseWhite" />
        </Flex>
        <Box
          height="88px"
          width="1px"
          bg="extsy.neutral900"
          display={{
            base: "none",
            lg: "block",
          }}
        />
        <Flex flexDir="column" gap="8px" w={{ base: "230px", lg: "100%" }}>
          <CustomText
            text={t("available")}
            color="extsy.baseWhite"
            size="14px"
          />
          <CustomText
            text={"0.00 BTC"}
            size={{ base: "24px", lg: "32px" }}
            weight={{ base: "600", lg: "700" }}
            color="extsy.baseWhite"
          />
          <CustomText text={"~ $ 0.00"} size="14px" color="extsy.baseWhite" />
        </Flex>
        <Box
          height="88px"
          width="1px"
          bg="extsy.neutral900"
          display={{
            base: "none",
            lg: "block",
          }}
        />
        <Flex flexDir="column" gap="8px" w={{ base: "230px", lg: "100%" }}>
          <CustomText
            text={t("currentCashback")}
            color="extsy.baseWhite"
            size="14px"
          />
          <CustomText
            text={"0.00 BTC"}
            size={{ base: "24px", lg: "32px" }}
            weight={{ base: "600", lg: "700" }}
            color="extsy.baseWhite"
          />
          <CustomText
            text={t("ofTheVolume")}
            size="14px"
            color="extsy.baseWhite"
          />
        </Flex>
      </Flex>
      <Box
        pos="absolute"
        bg="#4845FF"
        height="486px"
        width="486px"
        borderRadius="486px"
        filter="blur(97px)"
        zIndex="1"
        top="0"
        right="0"
        mt="-470px"
        mr={{ base: "-400px", lg: "-50px" }}
      />
    </Flex>
  );
}

export default ExtsyStats;
