import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import AMLCard from "./AMLCard";
import CheckCounter from "./CheckCounter";
import CustomText from "../../components/text/CustomText";
import AMLHistory from "./AMLHistory";

function AmlCheck() {
  return (
    <div>
      <Flex
        gap={{ base: "16px", lg: "24px" }}
        width="100%"
        flexDir={{
          base: "column",
          lg: "row",
        }}
      >
        <AMLCard />
        <CheckCounter />
      </Flex>
      <Box
        p={{ base: "16px", lg: "24px" }}
        bg="extsy.neutral1000"
        boxShadow=" 0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
        borderRadius={{ base: "16px", lg: "24px" }}
        mt="24px"
      >
        <Flex flexDir="column" gap={{ base: "16px", lg: "24px" }}>
          <CustomText
            text="Request History"
            size={{ base: "18px", lg: "24px" }}
            lh={{ lg: "34px" }}
            weight={{ base: "600", lg: "700" }}
            family="Titillium Web"
            color="extsy.baseWhite"
          />
          <AMLHistory />
        </Flex>
      </Box>
    </div>
  );
}

export default AmlCheck;
