import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import {
  IconArrowRight,
  IconArrowsExchange,
  IconChevronDown,
  IconChevronUp,
  IconHistory,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { BALANCES } from "../../utils/constants";
import BuyGreenArrow from "../../assets/images/buy-green-arrow.svg";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import GradientText from "../../components/text/GradientText";

function Balances() {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "140px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Coin"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "190px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("amount")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "100px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text={t("available")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="283px" minW="374px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {BALANCES.map((balance, index) => (
              <Tr key={index}>
                <Td
                  height="54px"
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  py="0"
                >
                  <Flex gap="16px" align="center">
                    <Image
                      src={balance.icon}
                      alt={balance?.symbol}
                      boxSize="32px"
                    />
                    <CustomText
                      text={balance.symbol}
                      size="14px"
                      color="extsy.baseWhite"
                    />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" justify="space-between">
                    <CustomText text={balance.amount} size="14px" />
                    <CustomText
                      text={`~$${balance.amountUsd} USD`}
                      size="14px"
                      color="extsy.neutral600"
                    />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" justify="flex-end" gap="4px">
                    <CustomText text={balance.amount} size="14px" />
                    <CustomText text={balance.symbol} size="14px" />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex gap="16px" align="center">
                    <Flex cursor="pointer" gap="4px" align="center">
                      <Image
                        src={BuyGreenArrow}
                        alt="buy"
                        height="18px"
                        width="18px"
                      />
                      <CustomText
                        text={t("buy")}
                        size="10px"
                        color="#15B097"
                        cursor="pointer"
                      />
                    </Flex>
                    <Box bg="extsy.neutral900" height="9px" width="1px" />
                    <Flex cursor="pointer" gap="4px" align="center">
                      <IconArrowRight size="18px" color="#FFE9EB" />
                      <CustomText
                        text={t("sell")}
                        size="10px"
                        color="#ED3D4E"
                        cursor="pointer"
                      />
                    </Flex>
                    <Box bg="extsy.neutral900" height="9px" width="1px" />
                    <Flex cursor="pointer" gap="4px" align="center">
                      <IconArrowsExchange size="18px" color="#908B8B" />
                      <CustomText
                        text={"Trade"}
                        size="10px"
                        color="extsy.baseWhite"
                        cursor="pointer"
                      />
                    </Flex>
                    <Box bg="extsy.neutral900" height="9px" width="1px" />
                    <Flex cursor="pointer" gap="4px" align="center">
                      <IconHistory size="18px" color="#908B8B" />
                      <CustomText
                        text={"History"}
                        size="10px"
                        color="extsy.baseWhite"
                        cursor="pointer"
                      />
                    </Flex>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Balances;
