import { Box, Flex, Image, Input } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import CopyClipIco from "../../assets/images/copy-clip-ico.svg";
import PrimaryButton from "../button/PrimaryButton";

function RequestPayment() {
  const { t } = useTranslation();

  return (
    <Flex
      flexDir="column"
      gap="40px"
      p={{ base: "16px", lg: "32px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      border="1px solid"
      borderColor="extsy.neutral1000"
    >
      <CustomText
        text={t("requestPayout")}
        size={{ base: "18px", lg: "24px" }}
        weight={{ base: "600", lg: "700" }}
        color="extsy.white"
        family="Titillium Web"
        lh="32px"
      />
      <CustomText text={`${t("requestPaymentMinWithdrawal")} 0.000020 BTC.`} />
      <Flex flexDir="column" gap="24px">
        <Flex flexDir="column" gap="16px">
          <CustomText text={t("enterYourAddress")} family="Titillium Web" />
          <WithdrawInput
            placeholder={t("enterYourAddress")}
            type="text"
            icon={CopyClipIco}
          />
        </Flex>
        <Flex flexDir="column" gap="16px">
          <CustomText text={t("amount")} family="Titillium Web" />
          <WithdrawInput placeholder={t("withdrawAmount")} type="number" />
        </Flex>
        <CustomText text={`${t("minAmountIs")} 0.000020 BTC`} />
      </Flex>
      <PrimaryButton title={t("withdraw")} maxW={{ md: "272px" }} />
    </Flex>
  );
}

const WithdrawInput = ({ placeholder, type, icon }: any) => {
  return (
    <Box pos="relative">
      <Input
        pl="22px"
        pe="42px"
        height="56px"
        bg="extsy.neutral900"
        borderRadius="8px"
        fontSize="16px"
        color="extsy.white"
        fontFamily="Titilium Web"
        placeholder={placeholder}
        type={type}
        border="none"
        _focusVisible={{ border: "none" }}
        _placeholder={{
          color: "extsy.text",
        }}
      />
      {icon && (
        <Image
          src={icon}
          alt=""
          cursor="pointer"
          draggable={false}
          pos="absolute"
          top="0"
          right="0"
          mr="16px"
          mt="16px"
          height="24px"
          width="24px"
        />
      )}
    </Box>
  );
};

export default RequestPayment;
