import { Box, Flex, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../../components/text/CustomText";
import CustomSelect from "../../../components/form/CustomSelect";
import ETHIco from "../.../../../../assets/images/eth-ico.svg";
import USDIco from "../../../assets/images/usd-ico.svg";
import { IconArrowRight, IconMinus, IconPlus } from "@tabler/icons-react";
import CustomRadio from "../../../components/form/CustomRadio";

function Step1() {
  const [fixedPrice, setFixedPrice] = useState(260);
  return (
    <Flex flexDir="column" gap={{ base: "16px", md: "64px" }}>
      <Flex
        gap={{ base: "16px", md: "24px" }}
        align={{ md: "center" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Assets" size="14px" />
          <CustomSelect placeholder="ETH" ico={ETHIco} />
        </Flex>
        <Box
          mt="20px"
          display={{
            base: "none",
            md: "block",
          }}
        >
          <IconArrowRight size={24} color="#E35CAD" />
        </Box>
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="With Fiat" size="14px" />
          <CustomSelect placeholder="USD" ico={USDIco} />
        </Flex>
      </Flex>
      <Flex
        justify="space-between"
        gap={{ base: "16px", md: "24px" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Chain" size="14px" />
          <CustomSelect placeholder="Select Chain" />
        </Flex>
        <Box w="46px" display={{ base: "none", md: "block" }} />
        <Flex flexDir="column" gap="24px" w="100%">
          <Flex
            justify="space-between"
            gap={{ base: "16px", md: "24px" }}
            flexDir={{
              base: "column",
              md: "row",
            }}
          >
            <Flex justify="space-between">
              <CustomRadio label="Fixed" isChecked />
              <Box
                display={{
                  md: "none",
                  base: "block",
                }}
              >
                <CustomRadio label="Floating" />
              </Box>
            </Flex>
            <Flex flexDir="column" gap="16px">
              <Flex
                align="center"
                justify="space-between"
                gap="20px"
                h="56px"
                maxW={{ md: "419px" }}
                w="100%"
                p="8px 16px"
                borderRadius="12px"
                bg="extsy.neutral900"
              >
                <IconMinus
                  size={24}
                  color="#FAFAFA"
                  cursor="pointer"
                  onClick={() => setFixedPrice(fixedPrice - 1)}
                />
                <Input
                  value={fixedPrice}
                  onChange={(e) => setFixedPrice(Number(e.target.value))}
                  type="number"
                  fontSize="16px"
                  fontWeight="600"
                  color="extsy.text2"
                  textAlign="center"
                  border="none"
                  userSelect="none"
                  _hover={{ border: "none" }}
                  _focus={{ border: "none" }}
                  _focusVisible={{ border: "none" }}
                />
                <IconPlus
                  size={24}
                  color="#FAFAFA"
                  cursor="pointer"
                  onClick={() => setFixedPrice(fixedPrice + 1)}
                />
              </Flex>
              <CustomText
                text="Fixed price should be between Rs 220 - Rs 280."
                size="14px"
                color="extsy.primary"
              />
            </Flex>
          </Flex>
          <Box
            display={{
              md: "block",
              base: "none",
            }}
          >
            <CustomRadio label="Floating" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", md: "24px" }}
        align={{ md: "center" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
        mt={{ base: "24px", md: "0" }}
      >
        <Flex
          maxW={{ md: "339px" }}
          w="100%"
          flexDir={{ md: "column" }}
          align={{ base: "center", md: "auto" }}
          justify={{ base: "space-between", md: "auto" }}
          gap="16px"
        >
          <CustomText text="Your Price" size="14px" />
          <CustomText
            text="$ 278.70"
            size={{ base: "18px", md: "32px" }}
            weight={{ base: "600", md: "700" }}
            color="extsy.secondary"
          />
        </Flex>
        <Flex
          maxW={{ md: "339px" }}
          w="100%"
          flexDir={{ md: "column" }}
          align={{ base: "center", md: "auto" }}
          justify={{ base: "space-between", md: "auto" }}
          gap="16px"
        >
          <CustomText text="Highest Older Price" size="14px" />
          <CustomText
            text="$ 300.00"
            size={{ base: "18px", md: "32px" }}
            weight={{ base: "600", md: "700" }}
            color="extsy.secondary"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Step1;
