import { Box, Button, Flex, Image, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import ReceiptActiveIco from "../../assets/images/receipt-active-ico.svg";
import Buy from "./buy/Buy";
import Sell from "./sell/Sell";
import MyOrders from "./orders/MyOrders";
import P2PBalance from "./p2pBalance/P2PBalance";
import OrderDetails from "./orders/OrderDetails";
import Seller from "./seller";

function P2P() {
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const [activeP2PTab, setActiveP2PTab] = useState("Buy");

  return (
    <Box
      p={{ base: "16px", "2xl": "32px" }}
      bg="extsy.neutral1000"
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral1000"
    >
      <Flex align="center" justify="space-between">
        <Flex
          align={{ lg: "center" }}
          gap={{ base: "16px", lg: "32px" }}
          flexDir={{
            base: "column",
            lg: "row",
          }}
          w={{ base: "100%", lg: "auto" }}
        >
          <Flex gap="10px" align="center" justify="space-between">
            <CustomText
              text="P2P"
              size="24px"
              lh="34px"
              weight="700"
              family="Titillium Web"
              color="extsy.white"
            />
            <Box display={{ base: "block", lg: "none" }}>
              <ViewOrderButton onViewOpen={onViewOpen} />
            </Box>
          </Flex>
          <Flex align="center" gap="12px" wrap={{ base: "wrap", lg: "nowrap" }}>
            {P2P_TABS?.map((tab, index) => (
              <Button
                key={index}
                h="36px"
                px="26px"
                borderRadius="8px"
                bg={activeP2PTab === tab ? "extsy.g1" : "extsy.neutral900"}
                _hover={{
                  bg: activeP2PTab === tab ? "extsy.g1" : "extsy.neutral900",
                }}
                onClick={() => setActiveP2PTab(tab)}
              >
                <CustomText
                  text={tab}
                  size="14px"
                  cursor
                  color={activeP2PTab === tab ? "extsy.bg" : "extsy.neutral300"}
                />
              </Button>
            ))}
          </Flex>
        </Flex>
        <Box display={{ base: "none", lg: "block" }}>
          <ViewOrderButton onViewOpen={onViewOpen} />
        </Box>
      </Flex>
      <Box mt={{ base: "16px", lg: "40px" }}>
        {activeP2PTab === "P2P Blanace" ? (
          <P2PBalance />
        ) : activeP2PTab === "Buy" ? (
          <Buy />
        ) : activeP2PTab === "Sell" ? (
          <Sell />
        ) : activeP2PTab === "My Orders" ? (
          <MyOrders />
        ) : activeP2PTab === "Seller Account" ? (
          <Seller />
        ) : null}
      </Box>
      <OrderDetails isOpen={isViewOpen} onClose={onViewClose} />
    </Box>
  );
}

const ViewOrderButton = ({ onViewOpen }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      gap="10px"
      cursor="pointer"
      p="12px 16px 12px 12px"
      borderRadius="999px"
      border="1px solid"
      borderColor="#15B097"
      bg="extsy.bg"
      boxShadow="0px 0px 18px 0px rgba(21, 176, 151, 0.30)"
      onClick={onViewOpen}
    >
      <Image src={ReceiptActiveIco} alt="receipt" />
      <CustomText
        text="Active Order"
        size="12px"
        color="extsy.baseWhite"
        cursor
      />
    </Flex>
  );
};

const P2P_TABS = ["P2P Blanace", "Buy", "Sell", "My Orders", "Seller Account"];

export default P2P;
